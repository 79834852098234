
/*
 * Light and Temperater app for Sine
 * Author:Dheeraj
 * Created on:11-02-2021
 *
 */

// Imports
import React, { useEffect, useState } from 'react';
import './Slider.scss';

// Component Props
interface SliderWithProgressProps {
    progress: boolean,
    value: number,
    newValue: number,
    min: number,
    max: number,
    step: number,
    changeHandler: Function;
    wirelessLightingKnob:boolean;
    wirelessLighting:boolean;
}

/*
* Custom slider with progress to work as per the HUE screens
*/
function SliderWithProgress(props: SliderWithProgressProps) {
    // Component Props
    const { progress, value, newValue, min, max, changeHandler, step,wirelessLightingKnob } = props;
    // Component State
    const [actualValue, setActualValue] = useState(value);
    const [progressValue, setProgressValue] = useState(0);
    const [knobValue,setKnobValue] = useState(0);
    //set Knob_Value
    const newKnobValue=50;
    // useEffect
    //Update knob and value on actual value change
    useEffect(() => {
        setActualValue(value);
        setKnobValue(value);
        //To reset the slider progress on reload
        setProgressValue(0);
    }, [value]);
    // useEffect
    //Update the control based on the new value
    //based on the value and new value.
    useEffect(()=>{
        // if newValue is greater than existing value
        if (newValue > value) {
            setProgressValue(newValue);
        }
        else {
            setProgressValue(value);
            setActualValue(newValue);
        }
        setKnobValue(newValue);
    },[newValue]);
    // useEffect
    useEffect(() => {
        // if wirelessLightingKnob is true
        if (wirelessLightingKnob) {
            setActualValue(0);
            setProgressValue(0);
            setKnobValue(newKnobValue);
        } else  {
            setActualValue(value);
            setKnobValue(value);
        }
    }, [wirelessLightingKnob]);
    /*
    * Conditional rendering of progress.
    */
    const progressComponent = () => {
        if (value!==newValue) {
            // div for progress status
            return <div className='progress' style={{ width: `${progressValue}%` }} />;
        } else {
            return null;
        }
    };

    const customeSliderClassName=()=>{
        if(wirelessLightingKnob){
            return 'wireless';
        }else{
            return '';
        }
    };

    // Render component
    return (
        <div>
            {/* container */}
            <div className='flex row align-center justify-center'>
                {/* for min value */}
                <div id='sliderMinValue' className='minMaxValue'><span>{min}</span></div>
                {/* Progress bar */}
                <div style={{ width: '100%', position: 'relative' }}>
                    <div className='container' >
                        {/* input slider */}
                        <input id='customSlider' type='range' step={step} className={'slider ' + customeSliderClassName()} value={knobValue} style={{ width: '100%' }}
                            onChange={e => changeHandler(e)} disabled={progress} />
                        {/* div for progress status */}
                        {progressComponent()}
                        {/* div for actual value */}
                        <div className='value' style={{ width: `${actualValue}%` }} />
                    </div>
                </div>
                {/* max value */}
                <div id='sliderMaxValue' className='minMaxValue pls'><span>{max}</span></div>
            </div>
        </div>
    );
}

// Default Export
export default SliderWithProgress;