
/*
 * Author:Divya Tiwari
 * Created On: 26-07-2022
 * Updated On: 26-08-2022 by Thasneem Nizar
 *
 */
// Imports
import { Button, Card } from '@scuf/common';
import React from 'react';
import i18next from 'i18next';
import pin from '../../assets/loaction.svg';
import pin2 from '../../assets/unAuthorize.svg';
import './ErrorCard.scss';
import { useHistory } from 'react-router-dom';
/**
 *
 * view - state variable to return card component
 *
 */
interface IErrorCard{
  someThingWentWrong : boolean;
  unAuthorizedAccess: boolean;
  detectionFailed: boolean;
  onClickRetryButton?: Function;
  coordinateDetectionFailed?:boolean;
}

/**
 * Component
 * @param props
 * @returns React Element
 */
function ErrorCard(props: IErrorCard) {

  const history = useHistory();
    /**
   *
   * Returns a card component for some thing went wrong card
   *
   */

     const someThingWentWrongCard = () => {
      if (props.someThingWentWrong && !props.coordinateDetectionFailed) {
        // Render Error card
        return (
          <>
          <Card className="detecting-card" id="card">
            <img src={pin} className="error-pin"></img>
            <Card.Header
              title={i18next.t('someThingWentWrongHearder')}
              className="error-card-header" />
            <Card.Meta className="error-card-data">
              {i18next.t('someThingWentWrongData')}
            </Card.Meta>
          </Card>
          <Button className="retry-button" content={i18next.t('retryButton')} textTransform = {false} onClick={() => history.push('/login') } />
          </>
        );
      }
      else {
        // render nothing
        return null;
      }
    };
   /**
   *
   * Returns a card component for some thing went wrong card
   *
   */

    const unAuthorizedAccessCard = () => {
      if (props.unAuthorizedAccess) {
        // Render Error card
        return (
          <>
          <Card className="detecting-card" id="card">
            <img src={pin2} className="error-pin"></img>
            <Card.Header
              title={i18next.t('someThingWentWrongHearder')}
              className="error-card-header" />
            <Card.Meta className="error-card-data">
              {i18next.t('unAuthorizedAccessData')}
            </Card.Meta>
          </Card>
          <Button className="retry-button" content={i18next.t('retryButton')} textTransform = {false} onClick={() => props.onClickRetryButton?.()}></Button>
          </>
        );
      }
      else {
        // render nothing
        return null;
      }
    };
  /**
   *
   * @returns
   */
  const roomDetectionFailure = () => {
    if (props.detectionFailed || props.coordinateDetectionFailed) {
      // Render Error card
      return (
        <div className='room-detection-failure'>
          <img className='room-detection-failure-icon' src={pin} alt='Room detection' />
          <div className="room-detection-failure-title">Unable to detect your location</div>
          <div className="room-detection-failure-desc">Please check your location settings. If the issue persists please contact your site administrator.</div>
          <Button className="try-again-button" content={i18next.t('tryAgainButton')} textTransform = {false} onClick={() => history.push('/login')}></Button>
        </div>
      );
    }
    else {
      // render nothing
      return null;
    }
  };
  // Render component
  return (
    <React.Fragment>
      {someThingWentWrongCard()}
      {unAuthorizedAccessCard()}
      {roomDetectionFailure()}
    </React.Fragment>
  );

}
// Default export
export default ErrorCard;