
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

// Imports
import SamplePage from './pages/SamplePage/SamplePage';
/*
    - Routes.ts is the master router config file. Each item defines a route by its name and the parameters it accepts.
    - The component bound to the route will be loaded into the base route view component.
*/
export default [
    {
        name: 'sample',
        path: '/sample',
        component: SamplePage }];