
/*
 *
 * Author:Kruti Singh
 * Created on:
 */

// Imports
import { observable, action } from 'mobx';

// Global
declare global {
    // Window interface
    interface Window {
      ENV: {
        LOCALYTICS_SESSION_TIMEOUT: number;
        LAUNCHDARKLYCLIENTID:string;
        BASE_URL_LIGHT_TEMP:string;
        BASE_URL_NEEDROOM_API:string;
        BASE_URL_COMMON_SINE_UTIL:string;
      };
    }
  }

// Default global store class
export default class GlobalStore {
    // Sidebar collapsed
    @observable
    public sideBarCollapsed: boolean = false;
    // Localytics app key
    @observable
    public LOCALYTICS_APP_KEY: string = '';

    // Toggle sidebar
    @action
    toggleSideBar(state: boolean) {
        this.sideBarCollapsed = state;
    }

    // Set localytics app key
    @action
    setLocalyticsAppKey(state: string)
    {
      this.LOCALYTICS_APP_KEY = state;
    }
    // Fetch localytics app key
    @action
    fetchLocalyticsAppKey() {
      //make fetch call to get the Localytics APP Key
      //response = api_call
      //this.setLocalyticsAppKey(response);
      this.setLocalyticsAppKey('App-key-Value-from-API');
    }
}