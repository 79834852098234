
/*
 *
 * Author:V.Dheeraj
 * Created on:11/10/2021
 */

// Imports
import { ActionType } from 'typesafe-actions';
import { AUTH_SUCCESS, AUTH_FAILURE } from '../Actions/actionTypes';
import {authSuccess} from '../Actions/authActions';
//export
export type GlobalSharedActionTypes = ActionType<typeof authSuccess>;
// states
const initialState = {
    authenticated: false,
    token:null,
    siteId:null,
    latitude:null,
    longitude:null,
    level:null};
// Component
function usersReducer(state = initialState, action:GlobalSharedActionTypes) {
    //action
    switch (action.type) {
        // first case for auth success
        case AUTH_SUCCESS:
            // on auth success return states in payload
            const { token, siteId, latitude, longitude, level, encryptedSineSiteId, encryptedEmailId } = action.payload;
            return { ...state, token, siteId, latitude, longitude, level, authenticated:true, encryptedSineSiteId, encryptedEmailId };
        // second case for auth failure
        case AUTH_FAILURE:
            return { ...state };
        default:
            return state;
    }
}
// default export
export default usersReducer;