
/*
 * Author:V.Dheeraj
 * Created on:11/10/2021
 * Updated on:8/3/2022
 *
 */

// Imports
import React from 'react';
import { Button, Card } from '@scuf/common';
import '../../components/CustomSlideBar/Slider.scss';
import i18next from '../../i18next';
import pin2 from '../../assets/unAuthorize.svg';
import '../../components/ErrorCard/ErrorCard.scss';
import { RouteComponentProps } from 'react-router-dom';

// interface
//extends RouteComponentProps
interface UnAuthorizedPageProps extends RouteComponentProps {}

// component to show on un authorized access
function UnAuthorized(props: UnAuthorizedPageProps) {
    return (
        //card container
        <>
        <Card className="detecting-card" id="card">
            <img src={pin2} className="error-pin"></img>
            <Card.Header
              title={i18next.t('someThingWentWrongHearder')}
              className="detecting-card-header" />
            <Card.Meta className="detecting-card-data">
              {i18next.t('unAuthorizedAccessData')}
            </Card.Meta>
          </Card>
          <Button className="retry-button" content={i18next.t('retryButton')} onClick={() => props.history.push('/login')}></Button>
          </>
    );
}
//default export
export default UnAuthorized;