
/*
 *
 * Light and Temperater app for Sine
 * Author:Kruti Singh
 * Created on:11-02-2021
 * Updated on:08-29-2022 by Thasneem Nizar
 */
// Imports
import { Card, Divider, Icon, Input, Modal } from '@scuf/common';
import React from 'react';
import i18next from 'i18next';
import { AreaModel, IFloor } from '../../models/AreaModel';
import './FloorArea.scss';
import DetectingAreaCard from '../DetectingAreaCard/DetectingAreaCard';
import doubleRing from '../Loader/Double-Ring.gif';
import { IRoom, Room } from '../../models/Room';
import nearByRoomIconA from '../../assets/AIcon.svg';
import nearByRoomIconB from '../../assets/BIcon.svg';
import nearByRoomIconC from '../../assets/CIcon.svg';
import pin from '../../assets/arrow-left.svg';

// Component Props
interface IFloorAreaProps {
  setControls: Function;
  getAllFloors: Function;
  onFloorChange: Function;
  currentFloor: AreaModel;
  allFloors: Array<IFloor>;
  apiLoading: boolean;
  close: Function;
  hvacSignifynotavailable: boolean;
  selectionFailed: boolean;
  locationUnavailable: boolean;
  seeAnotherRoom: boolean;
  someThingWentWrong: boolean;
  // selectedFloor:Function;
  nearbyRooms: Array<IRoom>;
  selectedFloorNumber: string;
  showFloors: boolean;
}

// Component State
interface IFloorArea {
  showAllFloors: boolean;
  selectedFloor: string;
  currentFloorFilteredRooms: Array<Room>;
  filteredNearByRooms: Array<IRoom>;
  searchValue: string;
  sortValue: string;
  toggleSort: boolean;
  sortOption:string;
}
/**
 * Component
 * Props: IFloorAreaProps
 * State: IFloorArea
 */
class FloorArea extends React.Component<IFloorAreaProps, IFloorArea> {
  /**
   * Constructor
   */
  constructor(props: IFloorAreaProps) {
    // Inherit props
    super(props);
    // State
    this.state = {
      showAllFloors: true,
      selectedFloor: '',
      currentFloorFilteredRooms: [],
      searchValue: '',
      sortValue: 'aes',
      toggleSort: false,
      filteredNearByRooms: [],
      sortOption:'roomname'};
  }
  // stateOptions = ;

  /**
   * Calls when component is mounted
   */
  componentDidMount() {
    if (
      this.props.selectionFailed ||
      this.props.hvacSignifynotavailable ||
      this.props.seeAnotherRoom
    ) {
      this.setState({
        showAllFloors: false});
    }
    if (this.props.currentFloor) {
      this.setState({
        currentFloorFilteredRooms: this.props.currentFloor.rooms});
    }
    if(this.props.nearbyRooms) {
      this.setState({
        filteredNearByRooms: this.props.nearbyRooms});
    }
  }

  componentDidUpdate(prevProps: IFloorAreaProps) {
    if (this.props.currentFloor !== prevProps.currentFloor) {
      this.setState({
        currentFloorFilteredRooms: this.props.currentFloor.rooms});
    }
    if (this.props.nearbyRooms !== prevProps.nearbyRooms) {
      this.setState({
        filteredNearByRooms: this.props.nearbyRooms});
    }
  }

  /**
   * On Search Rooms
   */
  onSearchRooms = (searchTerm: string) => {
    const filteredValues = this.props.currentFloor.rooms.filter(room =>
      room.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredNearByRooms = this.props.nearbyRooms.filter(room =>
      room.locationName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({
      currentFloorFilteredRooms: filteredValues,
      filteredNearByRooms: filteredNearByRooms,
      searchValue: searchTerm});
  };
  /**
   * On Clear Search
   */
  onClearSearch = () => {
    this.setState({
      currentFloorFilteredRooms: this.props.currentFloor.rooms,
      filteredNearByRooms: this.props.nearbyRooms,
      searchValue: ''});
  };

  onSortChange = (value:string) => {
    this.setState({sortOption:value});
    if(value === 'roomname'){
      this.state.currentFloorFilteredRooms.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    }
    else{
      this.state.currentFloorFilteredRooms.sort((a, b) =>
      parseInt(a.roomNo, 10) - parseInt(b.roomNo, 10)
    );
    }

    if (this.state.sortValue === 'aes') {
      const reverse = [...this.state.currentFloorFilteredRooms].reverse();
      this.setState({
        currentFloorFilteredRooms: reverse,
        sortValue: 'des',
        toggleSort: !this.state.toggleSort});
    } else {
      this.setState({
        sortValue: 'aes',
        toggleSort: !this.state.toggleSort});
    }
  };

  onToggleSort = () => {
    this.setState({
      toggleSort: !this.state.toggleSort});
    };

  /**
   * Condiiton checking for no search results
   */

  onNoSearchResult = () =>
    !this.props.apiLoading &&
    this.props.seeAnotherRoom &&
    this.state.currentFloorFilteredRooms.length === 0 &&
    this.state.filteredNearByRooms.length === 0;

 /*
  * arrow icon for ascending order
  */
  arrowIconOnAscending =()=>{
    if(this.state.sortValue === 'aes')
    {
      return(
      <Icon
        className="arrow-icon arrow-icon-up"
        root="common"
        name="arrow-down"
        size="small"
      />);}
  };

  /*
  * arrow icon for dscending order
  */
  arrowIconOnDscending =()=>{
    if(this.state.sortValue === 'des')
    {
      return(
        <Icon
        className="arrow-icon arrow-icon-up"
        root="common"
        name="arrow-up"
        size="small"
      />);}
  };

   /*
  * arrow icon for sort-list dscending order
  */
   sortListArrowIconDscending =()=>{
    if(this.state.sortValue === 'des')
    {
      return(
        <Icon
        className="arrow-icon sort-list-icon sort-list-up-icon"
        root="common"
        name="arrow-up"
        size="small"
      />);}
  };

   /*
  * arrow icon for sort-list ascending order
  */
   sortListArrowIconAscending =()=>{
    if(this.state.sortValue === 'aes')
    {
      return(
        <Icon
        className="arrow-icon sort-list-icon"
        root="common"
        name="arrow-down"
        size="small"
      />);}
  };

   /*
  * loader for need to see rooms
  */
 SearchResultLoading =()=>{
  if(this.props.apiLoading) {
    return(
    <div className="doubleRing-card">
      <img src={doubleRing} className="doubleRing" />
    </div>);}
    };
   /*
  * sorted list of rooms by number
  */
 SortedListOfRoomByNumber =()=>{
  return(<div className="text-wrap" onClick={() => this.onSortChange('roomnumber')}>
  <p className="sort-list-text">Room Number - #</p>
  {(this.state.sortOption === 'roomnumber') &&
    <>
      {this.sortListArrowIconAscending()}
      {this.sortListArrowIconDscending()}
    </>
  }
  </div>);
 };
  /*
  * sorted list of rooms by name
  */
  SortedListOfRoomByName =()=>{
    return(<div className="text-wrap" onClick={() => this.onSortChange('roomname')}>
    <p className="sort-list-text">Room Name - A to Z</p>
    {(this.state.sortOption === 'roomname') &&
      <>
        {this.sortListArrowIconAscending()}
        {this.sortListArrowIconDscending()}
      </>
    }
  </div>);
   };
    /*
  * on clicking search icon cancel icon will appear
  */
  onClickingSearchIcon =()=>{
    if(this.state.searchValue){
    return( <div className="search-icon" onClick={() => this.onClearSearch()}>
    <Icon root="common" name="close" size="small" />
  </div>);}
   };
     /*
  * message on no search result
  */
  noSearchResultMessage =()=>{
    return(this.onNoSearchResult() && (
      <div className="no-results">
        <p className="no-results-header">No Matching results</p>
        <p className="no-results-text">
          Please try a different search term. You can search by room
          name or room number.
        </p>
      </div>));
   };
  /**
   *
   * renders search input and its results
   * if location is available
   *
   */
  renderSearch = () => {
      return (
        <>
        {this.props.seeAnotherRoom && (
          <div className="search">
            <Input
              placeholder="Search Rooms"
              className="search-input"
              value={this.state.searchValue}
              onChange={data => {
                this.onSearchRooms(data);
              }}
            />
            {this.onClickingSearchIcon()}
          </div>
        )}
        {this.noSearchResultMessage()}
        </>
      );
    };
    /**
     * Return Sort option
     */
    returnSort = () => {
      return (
        <>
        {(this.state.sortOption === 'roomname') && <p className="sort-text">A to Z</p> }
        {(this.state.sortOption === 'roomnumber') && <p className="sort-text">Room #</p> }
        </>
      );
    };
  /**
   *
   * renders all rooms list
   *
   */
  renderAllRooms = (name: string, isFromSlider: boolean) => {
    let floorClass ='';
    if (this.props.locationUnavailable){
      floorClass='all-floor';
    }
      return (
        <>
        {this.props.seeAnotherRoom && this.state.currentFloorFilteredRooms.length > 0 &&
          <div className="sort-room-list">
            {this.state.searchValue && (<p className="room-list-text">{this.state.currentFloorFilteredRooms.length} Matching Results</p>)}
            {!this.state.searchValue && ( <p className="room-list-text">All {name} Rooms</p>)}
            <div className="room-sorting" onClick={() => this.onToggleSort()}>
            {this.returnSort()}
            {this.arrowIconOnAscending()}
            {this.arrowIconOnDscending()}
          </div>
          {this.state.toggleSort && <div className="sort-list">
                {this.SortedListOfRoomByName()}
                <Divider className="dropdown-area-divider"/>
                {this.SortedListOfRoomByNumber()}
              </div>}
        </div>
        }
        {!this.props.apiLoading && (
          <div className={floorClass}>
            {this.state.currentFloorFilteredRooms &&
              this.state.currentFloorFilteredRooms.map(each => (
                <>
                  <a
                    key={each.floorId}
                    onClick={() => {
                      this.props.setControls(each.name, each.Id, isFromSlider);
                    }}
                  >
                    {/* div for area bar */}
                    <div className="area-bar">
                      {each.name.split('_').join(' ')}
                    </div>
                  </a>
                  <Divider className="area-divider" />
                </>
              ))}
          </div>
        )}
        </>
      );
    };

  /**
   *
   * renders a modal with the areas of the floor detected
   * if renderFloors == false renders other avaialble floors
   *
   */

  getZoneView = (name: string, isFromSlider: boolean) => {
    let autoDetectedFloorNumber = sessionStorage.getItem('level');
    let isRenderNearbyRooms = this.props.seeAnotherRoom && this.state.filteredNearByRooms.length > 0  && this.props.selectedFloorNumber !== '';
    // Render zone view
    return (
      <>
      {!this.state.showAllFloors && !isFromSlider && this.renderSelectedFloorTitle(name)}
      <div>
        {/* Renders the nearby rooms*/}
        {( isRenderNearbyRooms && this.props.selectedFloorNumber === autoDetectedFloorNumber) && this.renderNearbyRooms(isFromSlider)}
        {this.SearchResultLoading()}
        {/* Renders the all rooms*/}
        {this.renderAllRooms(name, isFromSlider)}
      </div>
      </>

    );
  };
  // compare floors
  sortCallback = (a: IFloor, b: IFloor) => ('' + a.name).localeCompare(b.name);

  /**
   * Get floor view
   * @returns React Element
   */
  getFloorView = () => {
    let sortedList = [...this.props.allFloors].sort((a, b) =>
      this.sortCallback(a, b)
    );
    return sortedList.map((each) => (
      // div for margin
      <>
      <div>
        <a
          key={each.floorId}
          onClick={() => {
            const flrNum = `${each.floorNumber}`;
            this.props.onFloorChange(each.floorId, 'BuildingStory', flrNum);
            this.setState({ showAllFloors: false, selectedFloor: each.name });
          }}
        >
          <div className="area-bar">{each.name.split('_').join(' ')} </div>
        </a>
      </div>
      <Divider />
      </>
    ));
  };

  /**
   * To get the Loader
   */
  getLoader = () => {
    if (this.props.apiLoading) {
      if(this.state.selectedFloor){
        return (
          <DetectingAreaCard loadingForAnotherFloorRoom ={true} loadingForAnotherRoom={false} loadingForHome={false} accessVerification={false} loadingForSelectedRoom={false} isLoadingForRoomInSlider={false} loadingForFloor={false}/>);
      } else {
        return (
          <DetectingAreaCard loadingForAnotherRoom={false} loadingForHome={false} accessVerification={false} loadingForSelectedRoom={false} isLoadingForRoomInSlider={false} loadingForFloor={true}/>);
      }}
  };

  roomLoader = () => {
    const name = this.state.selectedFloor;
    let contentClass ='';
    if (this.props.apiLoading) {
      contentClass = 'card-content';
      return (
        <Card className="not-detecting-card" id="card">
          {!this.props.selectionFailed && (
            <>
              <Card.Header
                title={`Select the room you\'re on`}
                className="not-detecting-card-header"
              />
              {this.getCardHeaderText()}
            </>
          )}
          <Card.Content className={contentClass}>{this.getZoneView(name, false)}</Card.Content>
        </Card>
      );
    }
  };

  /**
   * To get Card Header Text
   */
  getCardHeaderText = () => {
    const name = this.state.selectedFloor;

    if (this.state.showAllFloors) {
      return (
        <Card.Meta className="not-detecting-card-data">
          We are not able to detect where you are. Please select the floor you’re on.
        </Card.Meta>
      );
    } else if (
      (!this.state.showAllFloors && this.props.apiLoading) ||
      (!this.state.showAllFloors && !this.props.apiLoading)
    ) {
      return (
        <Card.Meta className="not-detecting-card-data">
          {`Here is the list of all rooms on the ${name} that you have control access to. Please select the room you are in.`}
        </Card.Meta>
      );
    } else {
      return null;
    }
  };

  renderSelectedFloorTitle = (name:string) => {
    return (
      <div className='selected-floor-title'>
        {this.props.showFloors && <a
          className="selected-floor-click"
          onClick={() => {
            this.setState({ showAllFloors: true });
            if (this.props.allFloors.length === 0) {
              // get all floors
              this.props.getAllFloors();
            }
          }}
        >
          {/* back icon */}
          <div className="back-icon"><img src={pin} className='icon-image'></img></div>
          <div className='floor-name'>{name}</div>
        </a>}
        {!this.props.showFloors && <div className='floor-name-no-link'>{name}</div>}
      </div>
    );
  };
  renderContent = () => {
    const { name } = this.props.currentFloor;
    let contentClass = 'list-of-floor';
    let view = `room`;
    if (this.state.showAllFloors) {
      view = 'floor';
    } else {
      contentClass = 'card-content';
    }
    if (this.props.locationUnavailable || this.props.selectionFailed) {
      return (
        <>
        {!this.state.showAllFloors && this.props.apiLoading && this.roomLoader()}
          {this.state.showAllFloors && this.props.apiLoading && <DetectingAreaCard
            loadingForAnotherRoom={false}
            loadingForHome={this.props.apiLoading} accessVerification={false} loadingForSelectedRoom={false} isLoadingForRoomInSlider={false} loadingForFloor={false} />}
          {this.props.showFloors && !this.props.apiLoading && !this.props.someThingWentWrong && (
            <Card className="not-detecting-card" id="card">
              {!this.props.selectionFailed && (
                <>
                  <Card.Header
                    title={`Select the ${view} you\'re on`}
                    className="not-detecting-card-header"
                  />
                  {this.getCardHeaderText()}
                </>
              )}
              {this.props.selectionFailed && (
                <Card.Meta className="not-detecting-card-data mts">
                  {`Here is the list of all rooms on the ${name}. Please select a different room.`}
                </Card.Meta>
              )}
              <Card.Content className={contentClass}>
                {this.state.showAllFloors && this.getFloorView()}
                {!this.state.showAllFloors && this.getZoneView(name, false)}
              </Card.Content>
            </Card>
          )}
        </>
      );
    } else {
      let text='';
      if (!this.props.apiLoading && this.state.showAllFloors) {
       text = "Select the floor you're on";
      }
      return (
        <>
        {this.getLoader()}
        <Modal
          closeIcon={false}
          open={true}
          closeOnDimmerClick={false}
          className="area-modal"
          id="modal"
          onClose={() => this.props.close()}
        >
        <Modal.Header className="modal-header">
          <div>{text}</div>
      </Modal.Header>
      {/* {!this.props.someThingWentWrong && <> */}
        <div className='modal-div'>
          {/* div for selected floor title */}
          {!this.state.showAllFloors &&  this.renderSelectedFloorTitle(name)}
          {/* Renders the search*/}
          {!this.state.showAllFloors && this.renderSearch()}
          </div>
      <Modal.Content className='list-of-floor'>
        {!this.state.showAllFloors && this.getZoneView(name, true)}
        {this.state.showAllFloors && this.getFloorView()}
      </Modal.Content>
    </Modal>
  </>);}
  };

  /**
   *
   * renders nearby rooms list
   *
   */
   renderNearbyRooms = (isFromSlider: boolean) => {
   const radius0=0;
   const radius1=1;
   const radius2=2;
    // Render nearby rooms
    return (
      <div className='near-room-container'>
         {!this.props.apiLoading &&
        <>
        <div className='near-room-title'>{i18next.t('nearbyRoomTitle')}</div>
        {this.state.filteredNearByRooms.map(each => (
          <div className='near-room-sub-container'>
            <a
              key={each.id}
              onClick={() => {
                this.props.setControls(each.locationName, each.spatialElementId, isFromSlider);
              }}
            >
               {(each.iGroup == radius0) && (<img src={nearByRoomIconA} className="near-room-icon"></img>)}
               {(each.iGroup == radius1) && (<img src={nearByRoomIconB} className="near-room-icon"></img>)}
               {(each.iGroup == radius2) && (<img src={nearByRoomIconC} className="near-room-icon"></img>)}
               {(each.iGroup == (undefined || null)) && (<img src={nearByRoomIconB} className="near-room-icon"></img>)}
              <div className="near-room">{each.locationName}</div>
              <div className='near-room-radius'>{i18next.t('radiusText',{radius:each.radius})} </div>
            </a>
          </div>
        ))}
         </>}
      </div>
    );
  };

  // Render component
  render() {
    return <>{this.renderContent()}</>;
  }
}
// Default export
export default FloorArea;
