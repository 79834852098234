
/*
 * Author:Kruti Singh
 * Created on: 11-02-2021
 * Updated on: 07-23-2022 by Divya
 *
 */

// Imports
import React from 'react';
import { connect } from 'react-redux';
import { AuthResponse, ICoordinateData, IInitialState, IGetLocationByCoordsResponse } from '../../stores/Redux/models';
import TemperatureLightControl from '..//..//components/Temperature/TemperatureLightControl';
import DetectingAreaCard from '../../components/DetectingAreaCard/DetectingAreaCard';
import ErrorCard from '../../components/ErrorCard/ErrorCard';
// baseUrl
const lightTempBaseUrl = `${window.ENV.BASE_URL_LIGHT_TEMP}`;
// interface
interface IControlViewPageProps {}
// interface
interface IControlViewPage {
  viewPage: boolean;
  showFloors: boolean;
  coordinates: ICoordinateData;
  loading: boolean;
  locationUnavailable: boolean;
  noBeacons:boolean;
  transparentLoading: boolean;
  level: string;
  floorNumber: string;
  type: string;
  showAdditionalMessage: boolean;
  wirelessLighting:boolean;
  // loader form room navigation
  loadingForRoom:boolean;
  latitude: string;
  longitude:string;
  someThingWentWrong:boolean;
  isSliderControls:boolean;
  coordinateDetectionFailed:boolean;
}
//default Coordinates
const defaultCoordinates = {
  id: '',
  isHvacControlAvailable: false,
  isLightingControlAvailable: false,
  isPeopleCountAvailable: false,
  locationId: '',
  locationName: '',
  siteId: '',
  lightingSystem:'' };
// component
class ControlViewPage extends React.Component<
  IControlViewPageProps,
  IControlViewPage
> {
  /**
   *
   * viewPage : state variable to see 'view controls button' or Main page with all controls
   *
   * floors : data of floors to be rendered
   *
   */

  constructor(prop: IControlViewPageProps) {
    super(prop);
    this.state = {
      viewPage: false,
      showFloors: true,
      coordinates: defaultCoordinates,
      loading: false,
      locationUnavailable:false,
      transparentLoading: false,
      //@ts-ignore
      level: this.props.level,
      //@ts-ignore
      floorNumber: this.props.level,
      type: 'Zone',
      showAdditionalMessage: false,
      noBeacons:false,
      wirelessLighting:false,
      loadingForRoom:false,
      //@ts-ignore
      latitude : this.props.latitude,
      //@ts-ignore
      longitude : this.props.longitude,
      someThingWentWrong: false,
      isSliderControls:false,
      coordinateDetectionFailed:false};
  }
/**
 * changeFloor
 * @param floorId
 *
 */
  changeFloor = (floorId: string, floorNumber: string) => {
    this.setState({ level: floorId, type: 'BuildingStory', floorNumber: floorNumber });
  };
/**
 * processResponse
 * @param responseData
 *
 */
  processResponse = (responseData:AuthResponse)=>{
    let _coordinates = responseData.data[0];
    if (_coordinates.id) {
      this.setState({
        locationUnavailable: false});
    }
    let {isHvacControlAvailable, isLightingControlAvailable} = _coordinates;
    let _noBeacons = !(isHvacControlAvailable || isLightingControlAvailable);
    this.setState({showAdditionalMessage:_noBeacons});
    this.setState({noBeacons:_noBeacons});
    let _isWireless = _coordinates.lightingSystem === 'SignifyWireless';
    this.setState({wirelessLighting:_isWireless},()=>{
      this.setState({ coordinates: _coordinates});
    });
  };
// setLocationUnavalilable
setLocationUnavalilable=()=>{
  this.setState({ showAdditionalMessage: true, locationUnavailable:true, noBeacons:false });
};

validateResponse = (responseData:AuthResponse)=>{
  // valid response
  if (responseData.isSuccess) {
    if (responseData.data.length > 0) {
      this.processResponse(responseData);
    } else {
      this.setLocationUnavalilable();
    }
  } else {
    this.setLocationUnavalilable();
  }
};

/**
 * async
 * resolveCoordinatesUsingFloor
 * @param zone
 *
 */
  resolveCoordinatesUsingFloor = async (zone: string) => {
    let url = `${lightTempBaseUrl}/api/roomnavigation/GetBySystemAvailability`;
    let body = { SpatialElementId: zone };
    this.setState({ transparentLoading: true });
    // fetch api
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-LightControl-Auth': 'LightControlAPIKey'},
        body: JSON.stringify(body)});
      if (response.ok) {
        this.setState({ transparentLoading: false , someThingWentWrong:false});
        //valid response
        const responseData: AuthResponse = await response.json();
        this.validateResponse(responseData);
      } else {
        this.setState({ transparentLoading: false , someThingWentWrong:true});
        throw new Error();
      }
    } catch (err) {
      this.setState({ transparentLoading: false , someThingWentWrong:true});
      this.setLocationUnavalilable();
    } finally {
      this.setState({ transparentLoading: false , someThingWentWrong:false });
    }
  };
/**
 * processLatLongResponse
 * @param responseData
 *
 */
  processLatLongResponse = (responseData: Array<ICoordinateData>) => {
    if (responseData.length > 0) {
      let _coordinates = responseData[0];
      if (_coordinates.id) {
        this.setState({ locationUnavailable: false });
        let _isWireless = _coordinates.lightingSystem === 'SignifyWireless';
        this.setState({wirelessLighting:_isWireless});
        let {isHvacControlAvailable, isLightingControlAvailable} = _coordinates;
        let _noBeacons = false;
        if(!isHvacControlAvailable && !isLightingControlAvailable)
        {
         _noBeacons=true;
        }
        this.setState({noBeacons:_noBeacons});
      }
      this.setState({ coordinates: _coordinates });
    } else {
      this.setState({ locationUnavailable: true });
    }
  };
/**
 * resolveCoordinatesUsingLatlong
 * async
 *
 */
  resolveCoordinatesUsingLatlong = async () => {
    //@ts-ignore
    const { latitude, longitude, level, siteId } = this.props;
    const _tempData: ICoordinateData = { ...this.state.coordinates };
    _tempData.siteId = siteId;
    let url = `${lightTempBaseUrl}/api/roomnavigation/GetLocationsByCoordinates`;
    let body = { latitude, longitude, floorId: level, sineSiteId: siteId };
    this.setState({ loading: true, coordinates: _tempData });
    // fetch api
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-LightControl-Auth': 'LightControlAPIKey'},
        body: JSON.stringify(body)});
        this.setState({ loading: false });
      if (response.ok) {
        // Valid response
        const responseData: IGetLocationByCoordsResponse = await response.json();
        if (responseData) {
          this.setState({ showFloors: responseData.allowOtherFloorSelection,
            coordinateDetectionFailed:false,
            someThingWentWrong: false });
          this.processLatLongResponse(responseData.resolvedAreas);
        } else {
          // Invalid response
          this.setState({ locationUnavailable: true, coordinateDetectionFailed:true, someThingWentWrong: true });
        }
      }else{
        //Error Repsonse or API failure
        this.setState({ locationUnavailable: true ,coordinateDetectionFailed:true, someThingWentWrong: true });
      }
    } catch (err) {
      this.setState({ loading: false, locationUnavailable: true, coordinateDetectionFailed:true , someThingWentWrong: true});
    }
  };
/**
   * Calls when component is mounted
   */
  componentDidMount() {
    this.resolveCoordinatesUsingLatlong();
  }

  /*
   * changes the state of viewPage state variable
   *
   */
  setView(value: boolean) {
    this.setState({ viewPage: value });
  }
  /**
   *
   * Detect Location loader
   *
   */
   locationDetection=()=>{
    if (this.state.loadingForRoom) {
      // DetectingAreaCard component
      return <DetectingAreaCard
        loadingForAnotherRoom={false}
        loadingForHome={this.state.loadingForRoom} accessVerification={false} loadingForSelectedRoom={false} isLoadingForRoomInSlider={false} loadingForFloor={false} />;
    }
    return null;
  };
// render
  render() {
    let isRenderLoader = this.state.transparentLoading && !this.state.isSliderControls;
    return (
      <div>
        {/*loading card*/}
        {this.locationDetection()}
        <div style={{height:'90vh'}}>
        {/* TemperatureLightControl component*/}
        { isRenderLoader && <DetectingAreaCard loadingForAnotherRoom={false} loadingForHome={false} accessVerification={false} loadingForSelectedRoom={this.state.transparentLoading} isLoadingForRoomInSlider={false} loadingForFloor={false} />
        ||(!this.state.someThingWentWrong) && <TemperatureLightControl
            coordinates={this.state.coordinates}
            isDetecting={this.state.loading}
            locationUnavailable={this.state.locationUnavailable}
            wirelessLighting={this.state.wirelessLighting}
            noBeacons = {this.state.noBeacons}
            //@ts-ignore
            sineSiteId={this.props.siteId}
            //@ts-ignore
            level={this.state.level}
            type={this.state.type}
            resolveCoordinatesUsingFloor={this.resolveCoordinatesUsingFloor}
            changeFloor={this.changeFloor}
            showAdditionalMessage={this.state.showAdditionalMessage}
            latitude= {this.state.latitude}
            longitude = {this.state.longitude}
            selectedFloorNumber = {this.state.floorNumber}
            isSliderControls = {this.state.isSliderControls}
            setSliderControls = {(value:boolean) => this.setState({isSliderControls:value})}
            transparentLoading = {this.state.transparentLoading}
            showFloors = {this.state.showFloors}
          />  }
          {/* Error Card
            * Shown when API fails or
            * get location by coordinates API gives error response
            */}
        {(this.state.someThingWentWrong) &&
        <ErrorCard someThingWentWrong={this.state.someThingWentWrong}
        unAuthorizedAccess={false}
        detectionFailed={this.state.showFloors}
        coordinateDetectionFailed = {this.state.coordinateDetectionFailed}/> }
        </div>
      </div>
    );
  }
}
/**
 * mapStateToProps
 * @param state
 *
 */
const mapStateToProps = (state: IInitialState) => {
  const { latitude, longitude, level, siteId } = state.userContext;
  return {
    latitude,
    longitude,
    level,
    siteId};
};

// Default export
export default connect(mapStateToProps, null)(ControlViewPage);