
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

// Imports
import React from 'react';
import { Icon } from '@scuf/common';
import './SamplePage.scss';

/**
 * Component
 * @returns React Element
 */
const SamplePage = () => {
  // Sample component
  // Render component
  return (
    <div className="sample-page-container">
      <div className="sample-page">
        <h1>Sample Page</h1>
        <Icon name="document" size="xlarge"/>
        <h3> This page is yours to customize.</h3>
      </div>
    </div>
    );
};

// Default export
export default SamplePage;