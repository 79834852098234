
/**
 * Author:Kruti Singh
 * Created on:
*/
import i18next from 'i18next';
i18next.init({
  resources: require(`./locales/en-US/translations`).translations,
  initImmediate: false,
  lng: 'en-US',
});

export default i18next;
