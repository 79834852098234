
/*
 *
 * Author:Kruti Singh
 * Created on:
 * Updated on:22-03-2022 by V Dheeraj
 *
 */
// Imports
import { Card } from '@scuf/common';
import React from 'react';
import i18next from 'i18next';
import './TemperatureLightControl.scss';

// Component Props
interface IAreaHeadingCard {
  hideSeeAnotherRoom: boolean;
  seeAnotherRoom?: Function;
  siteName: string;
  noBeacons:boolean;
  selectionFailed:boolean;
}
/**
 * Component
 * @param props
 * @returns React Element
 *
 */
function areaHeadingCard(props: IAreaHeadingCard) {
  /**
   *
   * returns a card with the selected area
   *
   *
   */

  const seeAnotherRoom = ()=>{
    if (props.seeAnotherRoom) {
      props.seeAnotherRoom();
    }
  };
// return
  return (
    <Card className="header-card" id="card">
      {/* Card Header - siteName*/}
      <Card.Header className="header-title" title={props.siteName} />
      {/* Condition to seeAnotherRoom */}
      {!(props.hideSeeAnotherRoom || props.selectionFailed) && (
        <Card.Meta className="header-link">
          <a
            onClick={seeAnotherRoom}
            id="seeAnotherRoom"
          >
            {i18next.t('seeAnotherRoom')}
          </a>
        </Card.Meta>
      )}
      {/* Condition to show Message when selected room does not have any controls */}
      {props.hideSeeAnotherRoom && props.noBeacons && !props.selectionFailed && (
        <>
        {/* No control available message */}
          <Card.Meta className="header-link">
            The room you are currently in does not have any controls available.
          </Card.Meta>
          <button
          className='blueButton mtm mbl'
            onClick={seeAnotherRoom}
            id="seeAnotherRoom"
          >
            Load a different room
          </button>
        </>
      )}
      {/* Condition to show Message when selected room does not have any controls */}
      {props.hideSeeAnotherRoom && props.noBeacons && props.selectionFailed && (
        <>
          <Card.Meta className="header-link">
          This room does not have any controls.
          </Card.Meta>
        </>
      )}
    </Card>
  );
}
// Default export
export default areaHeadingCard;
