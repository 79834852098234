
/*
 *
 * Author:V.Dheeraj
 * Created on:11/10/2021
 *
 */

// export AUTH_SUCCESS
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
// export AUTH_FAILURE
export const AUTH_FAILURE = 'AUTH_FAILURE';