/**
 * Author:V.Dheeraj
 * Created on:11/10/2021
*/
import {combineReducers,createStore} from 'redux';
import usersReducer from './Reducers/usersReducer';


// other reducers needs to import here
// if there are other reducers , we can add here one by one
const rootReducers = combineReducers({
    userContext : usersReducer });


const store = createStore(rootReducers);

export default store;