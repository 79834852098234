
/*
 *
 * Author:Kruti Singh
 * Created On:27-11-2021
 * Updated On:23-07-2022 by Divya Tiwari
 *
 */

// Imports
import React from 'react';
import './TemperatureLightControl.scss';
import LightControl from '../CustomSlideBar/LightControl';
import DetectingAreaCard from '../DetectingAreaCard/DetectingAreaCard';
import FloorArea from '../Floors/FloorArea';
import AreaHeadingCard from './AreaHeadingCard';
import { AreaModel, IFloor } from '../../models/AreaModel';
import TemperatureControl from '../CustomTempControl/TemperatureControl';
import _ from 'lodash';
import UnavailableCard from '../CustomSlideBar/UnavailableCard';
import { ICoordinateData } from '../../stores/Redux/models';
import SineAppLoader from '../Loader/SineAppLoader';
import ErrorCard from '../ErrorCard/ErrorCard';
import { IRoom } from '../../models/Room';
import RestrictedRoom from '../RestrictedRoom/RestrictedRoom';

// Variables
let tempApiStatus:ITempResponse;
const delayTime = 3000;
const tempDebounce = 1000;
let timer: NodeJS.Timeout;
let temperatureTimeout = 120000;
const baseUrl = `${window.ENV.BASE_URL_NEEDROOM_API}`;
const lightTempBaseUrl = `${window.ENV.BASE_URL_LIGHT_TEMP}`;
const number100 = 100;
const restrictedStatusCode = 403;

// Component Temp Response
interface ITempResponse{
  temperature:number;
  setTemperature:number;
}
// Component Props
interface ITemperatureLightControlProps {
  coordinates:ICoordinateData;
  isDetecting:boolean;
  locationUnavailable:boolean;
  sineSiteId:string;
  level:string;
  resolveCoordinatesUsingFloor:Function;
  changeFloor:Function;
  type:string;
  showAdditionalMessage:boolean;
  noBeacons:boolean;
  wirelessLighting:boolean;
  latitude:string;
  longitude:string;
  selectedFloorNumber:string;
  isSliderControls:boolean;
  setSliderControls:Function;
  transparentLoading:boolean;
  showFloors: boolean;
}
// Component State
interface ITemperatureLightControl {
  siteName: string;
  loadingForAnotherRoom: boolean;
  location: string;
  currentLocaiton: AreaModel;
  needToSeeAnotherRoom: boolean;
  allFloors: Array<IFloor>;
  apiLoading: boolean;
  allCachedCurrentLocaitons: Array<AreaModel>;
  hvacDataAvailable: boolean;
  tryagainLoader: boolean;
  temperatureValue: number;
  temperatureToValue: number;
  setTemperatureValue: number;
  temperatureReqFailed: boolean;
  temperatureReqLoading: boolean;
  temperatureStatusMsg: string;
  tempMin:number;
  tempMax:number;
  tempUnit:string;
  disableTempControl:boolean;
  isTemperatureControlLoading:boolean;
  isLightControlLoading:boolean;
  someThingWentWrong:boolean;
  someThingWentWrongRoomLoading:boolean;
  nearbyRooms:Array<IRoom>;
  brightness:number;
  brightnessdataavailable:boolean;
  isLightRestricted:boolean;
  isTemperatureRestricted:boolean;
  isTemperatureNull:boolean;
  loadingMsg: boolean;
}
// Common Ternary
const genericTernary = (
  validation: boolean,
  valueOne: string,
  valueTwo: string
) => {
  if (validation) {
    return valueOne;
  } else {
    return valueTwo;
  }
};
const controlTitle = 'BRIGHTNESS (ALL LIGHTS)';
/**
 * Component
 * Props: ITemperatureLightControlProps
 * State: ITemperatureLightControl
 */
class TemperatureLightControl extends React.Component<
  ITemperatureLightControlProps,
  ITemperatureLightControl
> {
  /**
   * Constructor
   */
  constructor(props: ITemperatureLightControlProps) {
    // Inherit props
    super(props);
    // State
    this.state = {
      siteName: '',
      loadingForAnotherRoom: false,
      location: '',
      needToSeeAnotherRoom: false,
      apiLoading: false,
      hvacDataAvailable: true,
      tryagainLoader: false,
      temperatureValue: 0,
      temperatureToValue: 0,
      setTemperatureValue:0,
      temperatureReqFailed: false,
      temperatureReqLoading: false,
      temperatureStatusMsg: 'CURRENT',
      tempMin: 0,
      tempMax: 100,
      tempUnit: 'C',
      disableTempControl: false,
      isTemperatureControlLoading:false,
      isLightControlLoading:false,
      someThingWentWrong:false,
      someThingWentWrongRoomLoading:false,
      currentLocaiton: {
        siteId: '',
        floorId: '',
        name: '',
        description: '',
        floorNumber: 0,
        rooms: []},
      allFloors: [],
      allCachedCurrentLocaitons: [
        {
          siteId: '',
          floorId: '',
          name: '',
          description: '',
          floorNumber: 0,
          rooms: []}],
      nearbyRooms:[],
      brightness:0,
      brightnessdataavailable:true,
      isLightRestricted: false,
      isTemperatureRestricted: false,
      isTemperatureNull: false,
      loadingMsg: false};
  }

  componentDidUpdate(
    prevProps: ITemperatureLightControlProps,
    prevState: ITemperatureLightControl
  ) {
    if (this.props.coordinates !== prevProps.coordinates) {
      if (this.props.coordinates.id !== prevProps.coordinates.id) {
        // reset retricted states that brings the restricted room page
        this.setState({
          isLightRestricted: false,
          isTemperatureRestricted: false,
        });
        clearInterval(timer);
        if (this.props.coordinates.isHvacControlAvailable) {
          this.getTemperature(true);
          timer = setInterval(() => {
            this.getTemperature(false);
          }, temperatureTimeout);
        }
        this.setState({
          siteName: this.props.coordinates.locationName,
          location: this.props.coordinates.id,
        });
        if (
          this.props.coordinates.isLightingControlAvailable &&
          !this.props.wirelessLighting
        ) {
          this.getBrightness();
        }
      }
    }
    if (this.props.locationUnavailable !== prevProps.locationUnavailable) {
      if (
        this.props.showFloors &&
        this.props.locationUnavailable &&
        this.state.allFloors.length === 0
      ) {
        this.getAllFloors();
      }
    }
  }

  /**
   * Get Floor on currrent room
   * @param location
   * @param spacialType
   */
  async getFloorsOnCurrentRoom(location: string, spacialType: string) {
    let _cachedLocation = this.state.allCachedCurrentLocaitons.find(
      (each) => each.floorId === location
    );
    if (_cachedLocation) {
      this.setState({
        currentLocaiton: _cachedLocation,
        loadingForAnotherRoom: false,
        apiLoading: false,
      });
      this.props.setSliderControls(false);
      return;
    }
    this.props.setSliderControls(false);
    if (!this.state.apiLoading) {
      this.setState({ apiLoading: true });
    }
    /**
     * Room controls API
     * Method: Get
     */
    let response = await fetch(
      `${baseUrl}/api/roomnavigation/${this.props.sineSiteId}/${spacialType}/${location}`
    );
    if (response.ok) {
      // Valid response
      let _currentLocation = await response.json();
      this.setState({
        currentLocaiton: _currentLocation,
        allCachedCurrentLocaitons: [
          ...this.state.allCachedCurrentLocaitons,
          _currentLocation,
        ],
        loadingForAnotherRoom: false,
        apiLoading: false,
      });
    } else {
      this.setState({
        someThingWentWrongRoomLoading: true,
        apiLoading: false,
        loadingForAnotherRoom: false,
      });
    }
  }

  /**
   * Get all floors
   * @param
   */
  async getAllFloors() {
    this.setState({ apiLoading: true });
    this.props.setSliderControls(false);
    /**
     * Room controls API
     * Method: Get
     */
    let response = await fetch(
      `${baseUrl}/api/roomnavigation/${this.props.sineSiteId}`
    );
    if (response.ok) {
      // Valid response
      let _allFloors = await response.json();
      this.setState({ allFloors: _allFloors, apiLoading: false });
    } else {
      this.setState({ someThingWentWrongRoomLoading: true, apiLoading: false });
    }
  }
  /**
   *
   * on selecting an area set control values according the selected area
   *
   */
  setControls(name: string, zoneId: string, isFromSlider: boolean) {
    this.props.setSliderControls(isFromSlider);
    this.props.resolveCoordinatesUsingFloor(zoneId);
    this.setState({
      siteName: name,
      needToSeeAnotherRoom: false,
      location: zoneId,
    });
  }

  /**
   *
   * Get nearby rooms API call
   *
   */
  async getNearbyRooms() {
    let autoDetectedFloor = sessionStorage.getItem('level');
    this.setState({ apiLoading: true });
    this.props.setSliderControls(false);
    let url = `${lightTempBaseUrl}/api/roomnavigation/GetNearByRooms`;
    let body = {
      SineSiteId: this.props.sineSiteId,
      FloorId: autoDetectedFloor,
      Latitude: this.props.latitude,
      Longitude: this.props.longitude,
      areaId: this.props.coordinates.locationId,
    };
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-LightControl-Auth': 'LightControlAPIKey',
      },
      body: JSON.stringify(body),
    });
    if (response.ok) {
      // Valid response
      let nearbyRooms = await response.json();
      this.setState({
        nearbyRooms: nearbyRooms,
      });
      /*
       * If valid response call room navigation API
       *
       */
      this.getFloorsOnCurrentRoom(this.props.level, this.props.type);
    } else {
      this.setState({
        apiLoading: false,
        loadingForAnotherRoom: false,
        someThingWentWrongRoomLoading: true,
      });
    }
  }

  /**
   *
   *
   *
   */
  onFloorchange = (floorId: string, type: string, floorNumber: string) => {
    this.props.changeFloor(floorId, floorNumber);
    this.getFloorsOnCurrentRoom(floorId, type);
  };

  setTempRequestFailed = () => {
    this.setState(
      { temperatureReqFailed: true, disableTempControl: false },
      () => {
        setTimeout(() => {
          this.setState({ temperatureReqFailed: false });
        }, delayTime);
      }
    );
  };

  /**
   * Process API Response for get temperature
   */
  processGetTemperatureApiResponse = async (response: Response) => {
    // Valid response
    let responseJson = await response.json();
    // Parse values
    let _value = parseFloat(responseJson.temperature);
    let _tempMin = parseInt(responseJson.minValue, 10);
    let _tempMax = parseInt(responseJson.maxValue, 10);
    let _tempUnit = responseJson.unit[0].toUpperCase();
    let _toValue = parseFloat(responseJson.setTemperature);
    this.validation(_value, _toValue, _tempMax, _tempMin);
    if (isNaN(_tempMin)) {
      _tempMin = 0;
    }
    if (_tempMax === 0 || isNaN(_tempMax)) {
      _tempMax = number100;
    }
    if (_value === 0 && (_toValue === 0 || isNaN(_toValue))) {
      this.setState({
        loadingMsg: true,
      });
      _toValue = _tempMin;
    }
    if(isNaN(_toValue) && (!isNaN(_value) && _value != 0)){
      this.setState({
        loadingMsg: true,
      });
      _toValue=_value;
    }

    if (!isNaN(_value)) {
      tempApiStatus = { temperature: _value, setTemperature: _toValue };
      this.setState(
        {
          temperatureValue: _value,
          temperatureToValue: _toValue,
          setTemperatureValue: _toValue,
          temperatureReqFailed: false,
          tempMax: _tempMax,
          tempMin: _tempMin,
          tempUnit: _tempUnit,
          disableTempControl: false,
        },
        () => {
          let statusMsg = this.getText(_toValue);
          this.setState({ temperatureStatusMsg: statusMsg });
        }
      );
      this.setState({
        hvacDataAvailable: true,
        isTemperatureRestricted: false,
        isTemperatureNull: false,
      });
    } else {
      this.setTempRequestFailed();
      this.setState({ hvacDataAvailable: false, isTemperatureNull: true });
      if (response.status === restrictedStatusCode) {
        this.setState({ isTemperatureRestricted: true });
      } else {
        this.setState({ isTemperatureRestricted: false });
      }
    }
  };

  validation = (_value: number, _toValue: number, _tempMax: number, _tempMin: number) => {
    const diff = _tempMax - _tempMin;
    const value = diff / 11;
    let cssValue = 1;
    const number500 = 100;
    if (
      (_value === 0 && (_toValue === 0 || isNaN(_toValue))) ||
      _value === _toValue ||
      _value === _tempMin ||
      _value < _tempMin || 
      _value >= _tempMax
    ) {
      const interval = setInterval(() => {
        let hidden = false;
        let hiddenOrange = false;
        const currentValue = document.getElementById(
          'current-slider-value-blue'
        );
        if (currentValue && !hidden) {
          currentValue.classList.add('display-none');
          hidden = true;
        }
        const currentValueOrange = document.getElementById(
          'current-slider-value-orange'
        );
        if (currentValueOrange && !hiddenOrange) {
          currentValueOrange.classList.add('display-none');
          hiddenOrange = true;
        }
        if(hidden || hiddenOrange) {
          clearInterval(interval);
        }
      }, number500);
    } else {
      this.setState({
        loadingMsg: false,
      });
      const currentValue = document.getElementById('current-slider-value-blue');
      if (currentValue) {
        currentValue.classList.add('current-value');
        currentValue.classList.remove('display-none');
      }
      setTimeout(() => {
        let hidden = false;
        const currentValueOrange = document.getElementById(
          'current-slider-value-orange'
        );
        if (currentValueOrange && !hidden) {
          currentValueOrange.classList.add('display-none');
          currentValueOrange.classList.add('current-value');
          currentValueOrange.classList.remove('display-none');
          hidden = true;
        }
      }, number100);
    }
    if (_value > _tempMin && _value < _tempMax) {
      for (let i = 1; i < 12; i++) {
        if (_value > _tempMin && _value < _tempMin + value * i) {
          cssValue = i;
          break;
        }
      }
      const number5000 = 500;
      setTimeout(() => {
        let hidden = false;
        let hiddenOrange = false;
        const currentValue = document.getElementById(
          'current-slider-value-blue'
        );
        if (currentValue && !hidden) {
          const classList = currentValue.className.split(/\s+/);
          for (var i = 0; i < classList.length; i++) {
            if (classList[i].includes('lt')) {
              currentValue.classList.remove(classList[i]);
            }
          }
          currentValue.classList.add(`lt-${cssValue}`);
          hidden = true;
        }
        const currentValueOrange = document.getElementById(
          'current-slider-value-orange'
        );
        if (currentValueOrange && !hiddenOrange) {
          const classList = currentValueOrange.className.split(/\s+/);
          for (var i = 0; i < classList.length; i++) {
            if (classList[i].includes('lt')) {
              currentValueOrange.classList.remove(classList[i]);
            }
          }
          currentValueOrange.classList.add(`lt-${cssValue}`);
          hiddenOrange = true;
        }
      }, number5000);
    }
  }

  /**
   * Get temperature API Call
   */
  getTemperature = async (isTimerOn: boolean) => {
    try {
      this.setState({ disableTempControl: true });
      if (isTimerOn) {
        this.setState({ isTemperatureControlLoading: true });
      }
      /**
       * Room controls API
       * Method: Get
       * Headers: X-LightControl-Auth
       */
      let response = await fetch(
        `${lightTempBaseUrl}/api/roomTemperature/${this.props.coordinates.id}`,
        {
          headers: { 'X-LightControl-Auth': 'LightControlAPIKey' },
        }
      );
      if (response.ok) {
        this.processGetTemperatureApiResponse(response);
      } else {
        this.setTempRequestFailed();
        this.setState({ hvacDataAvailable: false, isTemperatureNull: false });
        if (response.status === restrictedStatusCode) {
          this.setState({ isTemperatureRestricted: true });
        } else {
          this.setState({ isTemperatureRestricted: false });
        }
      }
    } catch {
      this.setState({
        hvacDataAvailable: false,
        disableTempControl: false,
        isTemperatureRestricted: false,
        isTemperatureNull: false,
      });
    }
    this.setState({ isTemperatureControlLoading: false });
  };

  /**
   * Get text
   * @param toValue
   * @returns
   */
  getText = (toValue: number) => {
    let text;
    let isSetTemp =
      isNaN(this.state.temperatureToValue) && this.state.temperatureValue >= 0;
    if (
      this.state.temperatureValue === this.state.temperatureToValue ||
      isSetTemp ||
      this.state.loadingMsg
    ) {
      text = 'CURRENT';
    } else if (toValue > this.state.temperatureValue) {
      text = genericTernary(
        this.state.temperatureReqLoading,
        'HEATING TO',
        'HEAT SET TO'
      );
    } else {
      text = genericTernary(
        this.state.temperatureReqLoading,
        'COOLING TO',
        'COOL SET TO'
      );
    }
    return text;
  };

  /**
   * Update temperature
   * @param toValue
   * @param oldtoValue
   */
  upateTemp = async (toValue: number, oldtoValue: number) => {
    try {
      let statusMsg: string;

      this.setState({ disableTempControl: true, loadingMsg: false });
      const response = await fetch(
        `${lightTempBaseUrl}/api/roomTemperature/${this.props.coordinates.id}/${toValue}/${this.state.setTemperatureValue}/${this.state.temperatureValue}`,
        {
          method: 'POST',
          headers: { 'X-LightControl-Auth': 'LightControlAPIKey' },
        }
      );
      if (response.ok) {
        //200 OK response is a success case.
        const json = await response.json();
        let _value = parseFloat(json.temperature);
        let _toValue = parseFloat(json.setTemperature);
        if (_value === 0 && (_toValue === 0 || isNaN(_toValue))) {
          this.setState({
            loadingMsg: true,
          });
          _toValue = this.state.tempMin;
        }
        if(isNaN(_toValue) && (!isNaN(_value) && _value != 0)){
          this.setState({
            loadingMsg: true,
          });
          _toValue=this.state.temperatureValue;
        }
        this.validation(_value, _toValue, this.state.tempMax, this.state.tempMin);
        if (!isNaN(_value)) {
          tempApiStatus = { temperature: _value, setTemperature: _toValue };
          this.setState(
            {
              temperatureValue: _value,
              temperatureToValue: _toValue,
              setTemperatureValue: _toValue,
              temperatureReqFailed: false,
              hvacDataAvailable: true,
              temperatureReqLoading: false,
              loadingMsg: false,
              disableTempControl: false,
              isTemperatureNull: false,
            },
            () => {
              statusMsg = this.getText(toValue);
              this.setState({ temperatureStatusMsg: statusMsg });
            }
          );
        } else {
          this.setState({
            hvacDataAvailable: false,
            loadingMsg: false,
            temperatureReqLoading: false,
            isTemperatureNull: true,
          });
          this.setTempRequestFailed();
        }
      } else {
        this.setState(
          {
            temperatureToValue: tempApiStatus.setTemperature,
            setTemperatureValue: tempApiStatus.setTemperature,
            loadingMsg: false,
            hvacDataAvailable: false,
            temperatureReqLoading: false,
            isTemperatureNull: false,
          },
          () => {
            statusMsg = this.getText(toValue);
            this.setState({ temperatureStatusMsg: statusMsg });
          }
        );
        this.setTempRequestFailed();
      }
    } catch {
      this.setState(
        {
          temperatureToValue: tempApiStatus.setTemperature,
          temperatureReqLoading: false,
          loadingMsg: false,
          isTemperatureNull: false,
        },
        () => {
          let statusMsg = this.getText(tempApiStatus.setTemperature);
          this.setState({ temperatureStatusMsg: statusMsg });
        }
      );
      this.setTempRequestFailed();
    }
  };

  updateTemperature = _.debounce((toValue: number, oldtoValue: number) => {
    this.setState({
      loadingMsg: false,
    });
    this.upateTemp(toValue, oldtoValue);
  }, tempDebounce);

  /**
   *
   *
   * When clicking on need to see another room
   *
   */

  seeAnotherRommCallback = () => {
    this.setState({
      loadingForAnotherRoom: true,
      needToSeeAnotherRoom: true,
    });
    let autoDetectedFloor = sessionStorage.getItem('level');
    if (
      this.props.latitude &&
      this.props.longitude &&
      this.props.selectedFloorNumber === autoDetectedFloor
    ) {
      this.getNearbyRooms();
    } else {
      this.getFloorsOnCurrentRoom(this.props.level, this.props.type);
    }
  };

  /**
   * Get Brightness
   * @param
   * @returns
   * To connect to signify and adjust brightness
   */
  async getBrightness() {
    try {
      // Set loading true
      this.setState({ isLightControlLoading: true });
      /**
       * Fetch Signify details
       * Method: GET
       * Headers: X-LightControl-Auth
       * To return signify points detail
       */
      let response = await fetch(
        `${lightTempBaseUrl}/api/roomlighting/${this.props.coordinates.id}`,
        {
          headers: { 'X-LightControl-Auth': 'LightControlAPIKey' },
        }
      );
      if (response.ok) {
        // Valid response
        let _currentBrightness = await response.json();
        // Current luminance value
        let _value = _currentBrightness.areaLevel;
        _value = parseInt(_value, 10);
        this.setState({ isLightRestricted: false });
        this.setState({ isLightControlLoading: false });
        this.setState({ brightness: _value, brightnessdataavailable: true });
      } else {
        if (response.status === restrictedStatusCode) {
          this.setState({ isLightRestricted: true });
        } else {
          this.setState({ isLightRestricted: false });
        }
        // Invalid response
        this.setState({ isLightControlLoading: false });
        this.setState({ brightnessdataavailable: false });
      }
    } catch {
      this.setState({ isLightRestricted: false });
      this.setState({ isLightControlLoading: false });
      this.setState({ brightnessdataavailable: false });
      if (this.props.wirelessLighting) {
        this.setState({ brightnessdataavailable: true });
      }
    }
  }

  /**
   *
   *
   * renders HVAC related cards conditionally
   *
   */

  renderTempControl() {
    return (
      <>
        {!this.state.hvacDataAvailable &&
          this.props.coordinates.isHvacControlAvailable && (
            <div className='header-card' id='card'>
              <UnavailableCard
                checkDataAvailability={() => this.getTemperature(true)}
                Info={
                  'It looks like we are having trouble connecting to the HVAC for this room'
                }
                header={''}
                isTempNull={this.state.isTemperatureNull}
              />
            </div>
          )}
        {this.state.hvacDataAvailable &&
          this.props.coordinates.isHvacControlAvailable && (
            <div className='temp-card'>
              <div
                className={genericTernary(
                  this.state.disableTempControl,
                  'disableControl',
                  ''
                )}
              >
                <TemperatureControl
                  min={this.state.tempMin}
                  max={this.state.tempMax}
                  step={0.5}
                  unit={this.state.tempUnit}
                  value={this.state.temperatureValue}
                  toValue={this.state.temperatureToValue}
                  progressText={this.state.temperatureStatusMsg}
                  onChange={(v: number) => {
                    this.validation(this.state.temperatureValue, v, this.state.tempMax, this.state.tempMin)
                    this.updateTemperature(v, this.state.temperatureToValue);
                    this.setState(
                      {
                        temperatureReqLoading: true,
                        temperatureToValue: v,
                        loadingMsg: false,
                      },
                      () => {
                        let statusMsg = this.getText(v);
                        this.setState({
                          temperatureStatusMsg: statusMsg,
                          loadingMsg: false,
                        });
                      }
                    );
                  }}
                  requestFailed={this.state.temperatureReqFailed}
                  tempReqInProgress={this.state.temperatureReqLoading}
                  loadingMsg={this.state.loadingMsg}
                />
              </div>
            </div>
          )}
      </>
    );
  }

  /**
   *
   *
   * renders light related cards conditionally
   *
   */
  renderLightControl() {
    let isRenderLightControl =
      this.props.coordinates.isLightingControlAvailable &&
      !this.props.isDetecting &&
      this.state.brightnessdataavailable;
    return (
      <>
        {/*Render Unavailable Card */}
        {!this.state.brightnessdataavailable &&
          this.props.coordinates.isLightingControlAvailable && (
            // message if Sine data is not available
            <div className='header-card' id='card'>
              <UnavailableCard
                checkDataAvailability={() => this.getBrightness()}
                Info={
                  'It looks like we are having trouble connecting to the lights for this room.'
                }
                header={controlTitle}
              />
            </div>
          )}
        {isRenderLightControl && (
          <div className='header-card'>
            <LightControl
              site={this.props.coordinates.siteId}
              systemGuid={this.props.coordinates.id}
              wirelessLighting={this.props.wirelessLighting}
              brightness={this.state.brightness}
              brightnessdataavailable={this.state.brightnessdataavailable}
              setBrightnessDataAvaialble={(value: boolean) =>
                this.setState({ brightnessdataavailable: value })
              }
            />
          </div>
        )}
      </>
    );
  }
  /**
   *
   *
   * header card , controls and modals fo the page rendered here conditionally
   *
   */
  renderControls() {
    let isErrorCard =
      !this.state.isTemperatureControlLoading && this.state.someThingWentWrong;
    let isControls =
      !this.state.isTemperatureControlLoading &&
      !this.state.isLightControlLoading;
    return (
      <>
        {/* Loader for get system availability API */}
        {this.props.transparentLoading && (
          <DetectingAreaCard
            loadingForAnotherRoom={false}
            loadingForHome={false}
            accessVerification={false}
            loadingForSelectedRoom={false}
            isLoadingForRoomInSlider={true}
            loadingForFloor={false}
          />
        )}
        {(this.state.isTemperatureControlLoading ||
          this.state.isLightControlLoading) &&
          this.props.isSliderControls && (
            <DetectingAreaCard
              loadingForAnotherRoom={false}
              loadingForHome={false}
              accessVerification={false}
              loadingForSelectedRoom={false}
              isLoadingForRoomInSlider={true}
              loadingForFloor={false}
            />
          )}
        {(this.state.isTemperatureControlLoading ||
          this.state.isLightControlLoading) &&
          !this.props.isSliderControls && (
            <DetectingAreaCard
              loadingForAnotherRoom={false}
              loadingForHome={false}
              accessVerification={false}
              loadingForSelectedRoom={
                this.state.isTemperatureControlLoading ||
                this.state.isLightControlLoading
              }
              isLoadingForRoomInSlider={false}
              loadingForFloor={false}
            />
          )}
        {isErrorCard && (
          <ErrorCard
            someThingWentWrong={this.state.someThingWentWrong}
            unAuthorizedAccess={false}
            detectionFailed={false}
          />
        )}
        {!this.state.isLightRestricted &&
          !this.state.isTemperatureRestricted &&
          isControls && (
            <div className='room-controls-page'>
              <AreaHeadingCard
                seeAnotherRoom={this.seeAnotherRommCallback}
                siteName={this.state.siteName}
                hideSeeAnotherRoom={false}
                noBeacons={this.props.noBeacons}
                selectionFailed={this.props.showAdditionalMessage}
              />
              {this.renderTempControl()}
              <hr className='line' />
              {this.renderLightControl()}
            </div>
          )}
        {(this.state.isLightRestricted || this.state.isTemperatureRestricted) &&
          isControls && (
            <div className='room-controls-restricted-page'>
              <RestrictedRoom
                seeAnotherRoom={this.seeAnotherRommCallback}
                roomName={this.state.siteName}
              />
            </div>
          )}
      </>
    );
  }

  // Render component
  render() {
    let showAllFloors = false;
    let _locUnavailable =
      this.props.locationUnavailable && !this.props.isDetecting;
    let _seeAnotherRoom =
      this.state.needToSeeAnotherRoom && !this.state.loadingForAnotherRoom;
    if (
      _locUnavailable ||
      _seeAnotherRoom ||
      this.props.showAdditionalMessage
    ) {
      showAllFloors = true;
    }
    let _renderControls =
      !this.props.isDetecting &&
      !this.props.locationUnavailable &&
      !this.props.noBeacons;
    return (
      <>
        <div className={`temperature-light-control-container`}>
          {/* <NavigationBar setView={this.props.setView} /> */}
          {this.state.tryagainLoader && <SineAppLoader />}
          {this.props.noBeacons && !this.props.showAdditionalMessage && (
            <AreaHeadingCard
              siteName='No room controls found'
              hideSeeAnotherRoom={true}
              seeAnotherRoom={this.seeAnotherRommCallback}
              noBeacons={this.props.noBeacons}
              selectionFailed={this.props.showAdditionalMessage}
            />
          )}
          {!this.state.someThingWentWrongRoomLoading && (
            <>
              {' '}
              {_renderControls && this.renderControls()}
              {showAllFloors && (
                <div>
                  {this.props.showAdditionalMessage && (
                    <AreaHeadingCard
                      siteName={this.state.siteName}
                      hideSeeAnotherRoom={true}
                      noBeacons={this.props.noBeacons}
                      selectionFailed={this.props.showAdditionalMessage}
                    />
                  )}
                  <FloorArea
                    setControls={this.setControls.bind(this)}
                    getAllFloors={this.getAllFloors.bind(this)}
                    currentFloor={this.state.currentLocaiton}
                    allFloors={this.state.allFloors}
                    apiLoading={this.state.apiLoading}
                    onFloorChange={this.onFloorchange.bind(this)}
                    hvacSignifynotavailable={this.props.noBeacons}
                    close={() => {
                      this.setState({ needToSeeAnotherRoom: false });
                    }}
                    locationUnavailable={this.props.locationUnavailable}
                    selectionFailed={this.props.showAdditionalMessage}
                    seeAnotherRoom={this.state.needToSeeAnotherRoom}
                    someThingWentWrong={
                      this.state.someThingWentWrongRoomLoading
                    }
                    nearbyRooms={this.state.nearbyRooms}
                    selectedFloorNumber={this.props.selectedFloorNumber}
                    showFloors={this.props.showFloors}
                  />
                </div>
              )}
            </>
          )}
          <DetectingAreaCard
            loadingForAnotherRoom={this.state.loadingForAnotherRoom}
            loadingForHome={this.props.isDetecting}
            accessVerification={false}
            loadingForSelectedRoom={false}
            isLoadingForRoomInSlider={false}
            loadingForFloor={false}
          />
          {
            <ErrorCard
              someThingWentWrong={this.state.someThingWentWrongRoomLoading}
              unAuthorizedAccess={false}
              detectionFailed={_locUnavailable && !this.props.showFloors}
            />
          }
        </div>
      </>
    );
  }
}
// Default export
export default TemperatureLightControl;
