
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

// Imports
import { RouterStore } from 'mobx-router5';
import { Route, Params } from 'router5/create-router';

// We extend the Route class from router5 to be able to read the params on the route.
// This gives us an observable in the route store we can read from.
// Then simply extend the RouterStore to use it.
export interface IDeviceRoute extends Route {
    params: Params;
}
export class GlobalRouterStore extends RouterStore {
    public route!: IDeviceRoute;
}
const routerStore = new GlobalRouterStore();

export default routerStore;