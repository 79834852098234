
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

// Default class
export default class FeatureState {
    public isDeleteEnabled = true;
    // Set Delete Enabled
    public setIsDeleteEnabled(isDeleteEnabled:boolean) {
    // update isDeleteEnabled
        this.isDeleteEnabled = isDeleteEnabled;
    }
}