
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

import React, { Component, Fragment, lazy, Suspense } from 'react';
import { observer } from 'mobx-react';
import { RouterStore } from 'mobx-router5';
import LaunchDarkly, { LDUser, LDClient } from 'launchdarkly-node-server-sdk';
import FeatureState from './stores/FeatureState';
import { AnalyticsComponent } from './utils/Analytics/UseAnalytics';
import TodoStore from './stores/TodoStore/TodoStore';
import { GlobalRouterStore } from './stores/RouterStore';
import { launchDarklyUser, launchDarklyFeatureToggleKey } from './launchDarklyConfig';
import GlobalStore from './stores/GlobalStore/GlobalStore';
import ControlViewPage from '../src/pages/TemperatureLightControlPage/ControlViewPage';
import { BrowserRouter,Switch,Route } from 'react-router-dom';
import SineAppLoader from './components/Loader/SineAppLoader';
import UnAuthorized from './pages/Authentication/UnAuthorized';
import ProtectedRoute from './ProtectedRoute';
import './app.scss';
const LandingPage = lazy(()=>import('./pages/Authentication/LandingPage'));

// Component Props
export interface IProps {
  routerStore: RouterStore;
  globalStore: GlobalStore;
}
declare var fetchEnvVars: any;
/**
 * Component
 * Props: IProps
 */
@observer
export class App extends Component<{routerStore?: GlobalRouterStore, globalStore?: GlobalStore, todoStore?: TodoStore}, FeatureState> {
  // Features
  public featureState: FeatureState = new FeatureState();
  // Constructor
  constructor(props:{routerStore?: GlobalRouterStore, globalStore?: GlobalStore, todoStore?: TodoStore}) {
    // Inherit the component props
    super(props);
    // Defining LD object
    let user = launchDarklyUser as LDUser;
    if (typeof fetchEnvVars !== 'undefined') {
      fetchEnvVars
        .then((data: any) => {
          // Launch Darkly Initialization
          let client = LaunchDarkly.init(
            data[window.ENV.LAUNCHDARKLYCLIENTID] as string,
            user
          );
          // LD Ready
          client.on('ready', this.getFeatureFlags.bind(this, client));
          // LD Change
          client.on('change', this.getFeatureFlags.bind(this,client));
        })
        .catch(function notOk(err: any) {
          // Error
          console.error(err);
        });
    }
    //create a variable
    let userAgent = window.navigator.userAgent || window.navigator.vendor ;
    //condition for android
    if (/android/i.test(userAgent)) {
      document.documentElement.style.setProperty('--font-type', 'Roboto');
    }
    //condition for ios
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.documentElement.style.setProperty('--font-type', 'SFPro');
    }
  }

  /**
   * Get feature flags
   * @param client
   * type: LDClient
   */
  async getFeatureFlags(client: LDClient) {
    // Determines the variation of flags
    let featureState = await client.variation(launchDarklyFeatureToggleKey.DELETE_FEATURE_KEY,launchDarklyUser, false);
    this.featureState.setIsDeleteEnabled(
      featureState
    );
    // Update features into state
    this.setState(this.featureState);
  }

  // Component render
  render() {
    return (
      <Fragment>
        <Suspense fallback={<SineAppLoader/>}>
        <BrowserRouter>
          <Switch>
          {/* <ProtectedRoute path='/controlview' component={ControlViewPage} exact/> */}
          <ProtectedRoute path='/controlview' component={ControlViewPage} exact/>
          <Route path='/login' component={LandingPage} exact/>
          <Route path='/unauthorized' component={UnAuthorized} exact/>
          </Switch>
        </BrowserRouter>
        </Suspense>
        <AnalyticsComponent todoStore = {this.props.todoStore} globalStore = {this.props.globalStore}  />
      </Fragment>
    );
  }
}

// Default export
export default (App);
