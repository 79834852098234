/*
 * Author: Manikandabose Nagarajan
 * Created On: 11-01-2023
 * Updated On: 11-01-2023
 *
 */
// Imports
import React from 'react';
import i18next from 'i18next';
import './RestrictedRoom.scss';
import pin2 from '../../assets/unAuthorize.svg';

/**
 * Component Props
 * seeAnotherRoom
 * roomName
 *  */
interface IRestrictedRoomProps {
    seeAnotherRoom?: Function;
    roomName: string;
}
/**
 * Restricted Room
 */
function restrictedRoom(props: IRestrictedRoomProps) {
    // Destructuring props
    /**
     * roomName props
     */
    const { roomName } = props;
    // Need to see another room
    const seeAnotherRoom = () => {
        if (props.seeAnotherRoom) {
          props.seeAnotherRoom();
        }
    };
    // render component
    return(
        /**
         * Restricted Room Content
         */
        <div className='restricted-room'>
            <img className='restricted-room-icon' src={pin2} alt='Restricted' />
            {/**
             * Restricted access Header
             */}
            <div className="restricted-room-title">Restricted Access</div>
            <div className="restricted-room-desc">
                {/**
                * Restricted access Content
                */}
                <div className="section-one">
                    <div className="section-one-text">Seems like you do not have permission to access room comfort controls for</div>
                    <div className="section-one-roomname">{roomName}</div>
                </div>
                <div className="section-two">
                    <span className="section-two-text">If you believe you should have access, please contact your site administrator.</span>
                </div>
            </div>
            {/**
             * See Another Room
             */}
            <div className="another-room">
                <a onClick={seeAnotherRoom}>
                    {i18next.t('seeAnotherRoom')}
                </a>
            </div>
        </div>
    );
}
// Default export
export default restrictedRoom;