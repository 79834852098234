
/*
 *
 * Author:V.Dheeraj
 * Created on:11/10/2021
 *
 */

// Imports
import React from 'react';
import store from './stores/Redux/Store';
import { Route, Redirect, RouteProps } from 'react-router-dom';

/**
 * To verify the user is valid
 * @returns boolean
 */
const isValidUser = () => {
  let _store = store.getState();
  // check if the user is authenticated
  if (_store.userContext.authenticated) {
    return true;
  }
  return false;
};

// Props
interface PrivateRouteProps extends Omit<RouteProps, 'component'> {
  component: React.ElementType;
  // any additional vars
}

// component for private routes
const ProtectedRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // varify if valid user
      if (isValidUser()) {
        return (
          //render component
          <Component {...props} />
        );
      }
      // If the token is stored in session storage
      else if (sessionStorage.getItem('sineToken')){
        // Redirect to login
        props.history.push('/login');
      }
      else {
        return(
            //returnRedirect user
          <Redirect to={{ pathname: '/unauthorized' }} />
        );
      }
    }}
  />
);
// default export
export default ProtectedRoute;
