
/*
 * Author:Kruti Singh
 * Created On: 27-11-2021
 * Updated On: 31-05-2022 by Divya Tiwari
 *
 */
// Imports
import { Card, Modal } from '@scuf/common';
import React from 'react';
import i18next from 'i18next';
import pin from '../../assets/Detecting-location-pin.gif';
import './DetectingAreaCard.scss';
import doubleRing from '../Loader/Double-Ring.gif';

/**
 *
 * view - state variable to return card or modal component
 *
 */
interface IDetectingAreaCard {
  loadingForAnotherRoom: boolean;
  loadingForHome:boolean;
  accessVerification:boolean;
  loadingForSelectedRoom:boolean;
  isLoadingForRoomInSlider: boolean;
  loadingForFloor:boolean;
  loadingForAnotherFloorRoom?:boolean;}

/**
 * Component
 * @param props
 * @returns React Element
 */
function detectingAreaCard(props: IDetectingAreaCard) {
    /**
   *
   * Returns a card component for access verification
   *
   */

     const verifyingAccessLoader = () => {
      if (props.accessVerification) {
        // Render detecting card
        // for verifying access
        return (
          <Card className="detecting-card" id="card">
            <img src={pin} className="location-pin"></img>
            <Card.Header
              title={i18next.t('verifyingAccess')}
              className="detecting-card-header"
            />
            <Card.Meta className="detecting-card-data">
              {i18next.t('verifyingAccessData')}
            </Card.Meta>
          </Card>
        );
      }
      else {
        // render nothing
        return null;
      }
    };
  /**
   *
   * Returns a card component for detecting the area
   *
   */

  const detectingRoom = () => {
    if (props.loadingForHome) {
      // Render detecting card
      return (
        <Card className="detecting-card" id="card">
          <img src={pin} className="location-pin"></img>
          <Card.Header
            title={i18next.t('detectingRoom')}
            className="detecting-card-header"
          />
          <Card.Meta className="detecting-card-data">
            {i18next.t('pleaseWait2')}
          </Card.Meta>
        </Card>
      );
    }
    else {
      // render nothing
      return null;
    }
  };

  /**
   *
   * Returns a card component for loading room controls
   *
   */

   const loadingRoomControl = () => {
    if (props.loadingForSelectedRoom) {
      // Render detecting card
      // for loading room control data
      return (
        <Card className="detecting-card" id="card">
          <img src={pin} className="location-pin"></img>
          <Card.Header
            title={i18next.t('loadingRoomControl')}
            className="detecting-card-header"
          />
          <Card.Meta className="detecting-card-data">
            {i18next.t('loadingRoomControlData')}
          </Card.Meta>
        </Card>
      );
    }
    else {
      // render nothing
      return null;
    }
  };
  /**
   *
   * Returns a modal component for loading room controls
   *
   */

   const loadingRoomControlInModal = () => {
    if (props.isLoadingForRoomInSlider) {
      // Render detecting card
      //loading room controls
      return (
        <Modal
          open={true}
          closeOnDimmerClick={false}
          className="detecting-area-modal"
          id="modal"
        >
          <div className="icon-container">
            <img src={pin} className="location-pin-2" />
          </div>
          <Modal.Content className="modal-content">
            <p className="modal-header"> {i18next.t('loadingRoomControl')}</p>
            {i18next.t('loadingRoomControlData')}
          </Modal.Content>
        </Modal>
      );
    }
    else {
      // render nothing
      return null;
    }
  };
  /**
   *
   * Returns a Modal component for detecting the area
   *
   */
  const detectingNearByBeacons = () => {
    let headerData = '';
    let floorData = '';
    if (props.loadingForAnotherRoom || props.loadingForFloor || props.loadingForAnotherFloorRoom) {
      //Change header and data based on the prop
      if(props.loadingForAnotherRoom) {
        headerData = i18next.t('loadFloorRoomsTitle');
        floorData = i18next.t('loadFloorRoomsDescription');
      } else if (props.loadingForFloor){
        headerData = i18next.t('loadFloorTitle');
        floorData = i18next.t('loadFloorDescription');
      } else{
        headerData = i18next.t('loadFloorRoomsTitle');
        floorData = i18next.t('loadAnotherFloorRoomsDescription');
      }
      // Render Modal
      //for loading rooms on the floor
      return (
        <Modal
          open={true}
          closeOnDimmerClick={false}
          className="detecting-area-modal"
          id="modal"
        >
          <div className='doubleRing-card'>
      <img src={pin} className='doubleRing1'/>
      </div>
          <Modal.Content className="modal-content-room-floor">
            <p className="modal-header"> {headerData}</p>
            <p className='floor-data'>{floorData}</p>
          </Modal.Content>
        </Modal>
      );
    }
    else {
      // Render nothing
      return null;
    }
  };

  // Render component
  return (
    <React.Fragment>
      {/*verifying access loader*/}
      {verifyingAccessLoader()}
      {/*detecting room you are in loader*/}
      {detectingRoom()}
      {/*room control loader*/}
      {loadingRoomControl()}
      {/*detecting area you are in loader*/}
      {detectingNearByBeacons()}
      {loadingRoomControlInModal()}
    </React.Fragment>
  );

}
// Default export
export default detectingAreaCard;