
/*
 *
 * Light and Temperater app for Sine
 * Author:Dheeraj
 * Created on:11-02-2021
 * Updated on:22-03-2022 by dheeraj
 */

// Imports
import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import SliderWithProgress from './SliderWithProgress';
const controlTitle = 'BRIGHTNESS (ALL LIGHTS)';
const dimming = 'Dimming lights now….';
const dimmingSuccess = 'Finished dimming lights';
const brightning = 'Brightening now….';
const brightningWireless = 'Updating lights now….';
const brightningSuccess = 'Finished raising brightness';
const brightningWirelessSuccess = 'Finished updating brightness';
const failedupdate = 'Request Failed... Resetting lights';
const wirelessDefaultText = 'Tap on bar to set desired brightness level';
const radix = 10;
// base url
const BaseUrl = `${window.ENV.BASE_URL_LIGHT_TEMP}`;
const lightDebounce = 1000;

// Component Props
interface LightControlProps {
  site: string;
  systemGuid: string;
  wirelessLighting:boolean;
  brightness:number;
  brightnessdataavailable:boolean;
  setBrightnessDataAvaialble:Function;
}

/**
 * Component for controlling the lighting
 * @param props
 * @returns React Element
 */
function LightControl(props: LightControlProps) {
  // Component State
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [brightness, setBrightness] = useState(props.brightness);
  const [brightnessNew, setBrightnessNew] = useState(0);
  const [lightReqFailed, setlightReqFailed] = useState(false);
  const [wirelessDefault,setWirelessDefault] = useState(props.wirelessLighting);
  const [displayBrightness, setDisplayBrightness] = useState(0);

  const {systemGuid,wirelessLighting} = props;

  /**
   * To update the values based on wireless lighting
   *
   */
  useEffect(()=>{
    setWirelessDefault(wirelessLighting);
    // if wirelessLighting is true
    if(wirelessLighting) {
      setLoadingText(wirelessDefaultText);
      props.setBrightnessDataAvaialble(true);
      setBrightness(0);
      setBrightnessNew(0);
    } else {
      setLoadingText('');
    }
  },[wirelessLighting]);

  /**
   * To update the state value of wireless default
   *
   **/
  useEffect(() => {
    // if systemGuid is there and wirelessLighting is false
    if (systemGuid &&  !wirelessLighting) {
      setWirelessDefault(false);
    }
  }, [systemGuid]);

 /**
  *
   * To update the state value of display brightness
   *
   **/
  useEffect(() => {
    setDisplayBrightness(brightness);
  }, [brightness]);

  /**
   *
   * To update the state value of brightness
   * based on props
   * */

  useEffect(() => {
    if(brightness !== props.brightness){
      setBrightness(props.brightness);
    }
  }, [props.brightness]);

  /**
   * Update brightness
   * @param
   * @returns
   * To update luminance in signify
   */
  async function putBrightness(
    _newvalue: number,
    _oldvalue: number,
    isDimming: boolean,
    _isWireless:boolean,
    _systemGuid:string
  ) {
    setLoading(true);
    if (!isDimming) {
      setLoadingText(brightning);
      if (_isWireless){
        setLoadingText(brightningWireless);
      }
    } else {
      setLoadingText(dimming);
      if (_isWireless){
        setLoadingText(brightningWireless);
      }
    }

    // Request body
    var requestbody = {
      systemGuid: _systemGuid,
      points: [
        {
          value: _newvalue,
          quality: null,
          sampleTime: null}]};
    // Parse the request body
    const request = JSON.parse(JSON.stringify(requestbody));
    try {
      /**
       * Update Signify point details
       * Method: PUT
       * Headers: X-LightControl-Auth
       */
      let response = await fetch(
        `${BaseUrl}/api/roomlighting/${_systemGuid}/${_newvalue}/${_oldvalue}`,
        {
          method: 'PUT',
          headers: { 'X-LightControl-Auth': 'LightControlAPIKey' },
          body: request});
      if (response.ok) {
        // Valid response
        onSuccess(_newvalue, isDimming);
      } else {
        // Invalid response
        onApiCallFailure(_oldvalue, isDimming);
      }
    } catch {
      onApiCallFailure(_oldvalue, isDimming);
    }
  }

  /**
   * Api call failure
   * @param _oldvalue
   *
   */
  function onApiCallFailure(_oldvalue: number, isDimming: boolean) {
    setLoadingText(failedupdate);
    setlightReqFailed(true);
    setBrightnessNew(_oldvalue);
    setBrightness(_oldvalue);
    setDisplayBrightness(_oldvalue);
    setLoading(false);
    const timeout: number = 3000;
    setTimeout(() => {
      setLoadingText('');
      setlightReqFailed(false);
    }, timeout);
  }

  // Update brightness
  const updateBrightness = (
    toValue: number,
    oldtoValue: number,
    isDimming: boolean,
    _isWireless:boolean,
    _systemGuid:string
  ) => {
    putBrightness(toValue, oldtoValue, isDimming,_isWireless,_systemGuid);
  };

  //Callback for the debounce for smooth usage of control
  const debouncedUpdate = useCallback(
    //debounce function from loadash
    _.debounce(updateBrightness, lightDebounce),
    []
  );

  /*
   * on API Sucess
   */
  function onSuccess(value: number, isDimming: boolean) {
    setlightReqFailed(false);
    setBrightness(value);
    setLoading(false);
    setWirelessDefault(false);
    // if isDimming true
    if (isDimming) {
      if (wirelessLighting){
        setLoadingText(brightningWirelessSuccess);
      } else {
        setLoadingText(dimmingSuccess);
      }
    } else {
      if (wirelessLighting){
        setLoadingText(brightningWirelessSuccess);
      } else {
        setLoadingText(brightningSuccess);
      }
    }
    //need to revisit during the integration
    //chanes for the demo
    const timeout: number = 3000;
    setTimeout(() => {
      setLoadingText('');
    }, timeout);
  }

  /*
   * event handler
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let _value = parseInt(e.target.value, radix);
    //set brightness value
    setBrightnessNew(_value);
    //set display value of the control
    setDisplayBrightness(_value);
    let isDimming: boolean;
    //boolean for display text
    if (_value > brightness) {
      isDimming = false;
    } else {
      isDimming = true;
    }
    //All the code till this points gets execute on every change.
    //calling a debounce method. This gets executed only for the last value
    debouncedUpdate(_value, brightness, isDimming,wirelessLighting,systemGuid);
  };

  /*
   * brightness value based onthe inputs
   */
  let brighntessLabelText: string;
  if (displayBrightness === 0) {
    brighntessLabelText = 'Off';
  } else {
    brighntessLabelText = `${displayBrightness}%`;
  }

  // Render component
  return (
    <div>
      {/* container */}
      <div className="flex row justify-between align-center">
        {/* title container */}
        <div className="flex column align-start">
          <div className="medium-text bold-600">{controlTitle}</div>
          {/* if light request failed */}
          {lightReqFailed && (
            <span
              id="loadinngspan"
              className="medium-text red bold-600 min-height-24"
            >
              {loadingText}
            </span>
          )}
          {/* if light request passed */}
          {!lightReqFailed && (
            <span
              id="loadinngspan"
              className="medium-text purple bold-600 min-height-24"
            >
              {loadingText}
            </span>
          )}
        </div>
        {/* brightness label */}
        {!wirelessDefault && (
            <div className="large-text bold-600">{brighntessLabelText}</div>
        )}
      </div>
      {/* slider component */}
      <div className="mts">
        <SliderWithProgress
          step={1}
          min={0}
          max={100}
          changeHandler={handleChange}
          value={brightness}
          newValue={brightnessNew}
          progress={loading}
          wirelessLighting={props.wirelessLighting}
          wirelessLightingKnob={wirelessDefault}
        />
      </div>
    </div>
  );
}

// Default export
export default LightControl;
