
/*
 *
 * Light and Temperater app for Sine
 * Author:Dheeraj V
 * Created on: 11/19/2021
 * Updated on:1/18/2021
 */

//Imports
import React, { Component } from 'react';
import RoundSlider from './RoundSlider';
import './TemperatureControl.scss';
import { Icon, Tooltip } from '@scuf/common';
import $ from 'jquery';
const color1 = 'blue';
const color2 = 'orange';

// Component State
interface ITemperatureLightControl {
  reverse: boolean;
  rangeColor: string;
}

// Component Props
interface ITemperatureLightControlProps {
  min: number;
  max: number;
  step: number;
  value: number;
  toValue: number;
  onChange: Function;
  requestFailed: boolean;
  progressText: string;
  tempReqInProgress: boolean;
  unit: string;
  loadingMsg: boolean;
}

// Event interface
interface IEvent {
  value: number;
}

// Point interface
interface IPoint {
  value: string;
  preValue: string;
}

// Common ternary
const genericTernary = (
  validation: boolean,
  valueOne: number,
  valueTwo: number
) => {
  if (validation) {
    return valueOne;
  } else {
    return valueTwo;
  }
};
/**
 * Component
 * Props: ITemperatureLightControlProps
 * State: ITemperatureLightControl
 * @param
 * @returns React Element
 */
class TemperatureControl extends Component<
  ITemperatureLightControlProps,
  ITemperatureLightControl
> {
  // Constructor
  constructor(props: ITemperatureLightControlProps) {
    // Inhertit props
    super(props);
    // Bind methods to have the lexical scope
    this.onValChange = this.onValChange.bind(this);
    this.updateControl = this.updateControl.bind(this);
    this.onControlClick = this.onControlClick.bind(this);
    this.updateGradients = this.updateGradients.bind(this);
    this.convertAndUpdate = this.convertAndUpdate.bind(this);
    // State
    this.state = {
      reverse: false,
      rangeColor: color1 };
  }

  /**
   * Calulate reverse
   * @param value
   * @param toValue
   * @returns boolean
   */
  calculateReverst = (value: number, toValue: number) => toValue > value;

  /**
   * Update Control
   */
  updateControl = () => {
    // Destructure the props
    let { value, toValue } = this.props;
    // Update tooltip
    this.updateTooltip({ value: toValue });
    let _reverse = this.calculateReverst(value, toValue);
    if (this.state.reverse !== _reverse) {
      this.setState({ reverse: _reverse });
    }
    this.setGradient(_reverse, value, toValue);
  };

  /**
   * Calls when component is mounted
   */
  componentDidMount() {
    this.updateControl();
  }

  /**
   * Calls when component updated
   * @param prevProps
   */
  componentDidUpdate(prevProps: ITemperatureLightControlProps) {
    if (
      this.props.toValue !== prevProps.toValue ||
      this.props.value !== prevProps.value ||
      this.props.requestFailed !== prevProps.requestFailed ||
      this.props.tempReqInProgress !== prevProps.tempReqInProgress || 
      this.props.loadingMsg !== prevProps.loadingMsg
    ) {
      this.updateControl();
    }

    if(this.props.loadingMsg !== prevProps.loadingMsg) {
      this.updateTooltip({ value: this.props.toValue });
    }
  }

  /**
   * Set handle
   * @param reverse
   * @param value
   * @param toValue
   */
  setHandle = (reverse: boolean, value: number, toValue: number) => {
    // Slider selector
    let slider = document.getElementById('appearance2');
    // Slider class
    let firstHandle = slider && slider.getElementsByClassName('rs-first');
    let secondHandle = slider && slider.getElementsByClassName('rs-second');
    if (reverse) {
      // Reverse
      secondHandle && secondHandle[0].classList.add('display-none');
      firstHandle && firstHandle[0].classList.remove('display-none');
      // Set handle
      if (value !== toValue && firstHandle) {
        if ($(`#current-slider-value-orange`).length === 0) {
          $(firstHandle).append(
            `<div id='current-slider-value-orange' class=slider-value-on-path-orange>${value}</div>`
          );
        } else {
          $(`#current-slider-value-orange`)[0].innerText = value.toString();
        }
        firstHandle[0]
          .getElementsByClassName('rs-handle')[0]
          .classList.add('orange');
      } else {
        $('#current-slider-value-orange').remove();
      }
    } else {
      firstHandle && firstHandle[0].classList.add('display-none');
      secondHandle && secondHandle[0].classList.remove('display-none');
      if (secondHandle) {
        if ($(`#current-slider-value-blue`).length === 0) {
          $(secondHandle).append(
            `<div id='current-slider-value-blue' class=slider-value-on-path-blue>${value}</div>`
          );
        } else {
          if(!isNaN(value)) {
            $(`#current-slider-value-blue`)[0].innerText = value.toString();
          }
        }
        secondHandle[0]
          .getElementsByClassName('rs-handle')[0]
          .classList.add('blue');
      } else {
        $('#current-slider-value-blue').remove();
      }
    }
    //for base slider
    const base_slider1 = document.getElementById('appearance1');
    let base_slider0 = document.getElementById('appearance0');
    if (!this.props.tempReqInProgress) {
      base_slider0 = document.getElementById('appearance-disabled');
    }
    //base slider class
    const _firstHandle1 =
      base_slider1 && base_slider1.getElementsByClassName('rs-first');
    const _firstHandle0 =
      base_slider0 && base_slider0.getElementsByClassName('rs-first');
    // handle base slider
    if (value === toValue) {
      if (_firstHandle1) {
        _firstHandle1[0].classList.add('display-none');
      }
    } else {
      if (reverse) {
        _firstHandle1 && _firstHandle1[0].classList.remove('display-none');
      } else {
        _firstHandle0 && _firstHandle0[0].classList.remove('display-none');
      }
      if(isNaN(this.props.toValue)){
        _firstHandle0 && _firstHandle0[0].classList.add('display-none');
      }
    }
  };

  /**
   * Set Gradient
   * @param reverse
   * @param value
   * @param toValue
   */
  setGradient = (reverse: boolean, value: number, toValue: number) => {
    let startGradient, endGradient;
    const percentage = value;
    // Gradiant selector
    startGradient = document.getElementById('temp-dialup-start-gradient');
    endGradient = document.getElementById('temp-dialup-stop-gradient');
    let startGradientColor: string = '#BBBBBB';
    let endGradientColor: string = '#BBBBBB';
    // handle Gradiant
    if (startGradient && endGradient) {
      if (reverse) {
        if (this.props.tempReqInProgress) {
          startGradientColor = '#F8AA6A';
          endGradientColor = '#ED7310';
        }
        startGradient.setAttribute(
          'style',
          `stop-color: ${startGradientColor}; stop-opacity: ${genericTernary(
            value !== toValue,
            1,
            0
          )}`
        );
        startGradient.setAttribute('offset', `${percentage}%`);
        endGradient.setAttribute(
          'style',
          `stop-color: ${endGradientColor}; stop-opacity: ${genericTernary(
            value !== toValue,
            1,
            0
          )}`
        );
        endGradient.setAttribute('offset', '100%');
      } else {
        if (this.props.tempReqInProgress) {
          startGradientColor = '#3058E7';
          endGradientColor = '#39BBFF';
        }
        startGradient.setAttribute(
          'style',
          `stop-color: ${startGradientColor}; stop-opacity: ${genericTernary(
            value !== toValue,
            1,
            0
          )}`
        );
        startGradient.setAttribute('offset', '0%');
        endGradient.setAttribute(
          'style',
          `stop-color: ${endGradientColor}; stop-opacity: ${genericTernary(
            value !== toValue,
            1,
            0
          )}`
        );
        endGradient.setAttribute('offset', `${percentage}%`);
      }
    }
    this.setHandle(reverse, value, toValue);
  };

  /**
   * Value change
   * @param e
   */
  onValChange = (e: IPoint) => {
    // Attach any custom behavior here
    // for the round slider value changes
    //this.setState({ sliderValue: e.value });
    const { value, preValue } = e;
    let _changedValue;
    // handle changed value
    if (value.split(',')[0] !== preValue.split(',')[0]) {
      _changedValue = parseFloat(value.split(',')[0]);
    }
    if (value.split(',')[1] !== preValue.split(',')[1]) {
      _changedValue = parseFloat(value.split(',')[1]);
    }
    if (_changedValue) {
      if (_changedValue > this.props.value) {
        this.setState({ reverse: true });
        this.setState({ rangeColor: color1 });
        this.setGradient(true, this.props.value, this.props.toValue);
      } else {
        this.setState({ reverse: false });
        this.setState({ rangeColor: color2 });
        this.setGradient(false, this.props.value, this.props.toValue);
      }
      this.convertAndUpdate(_changedValue.toString());
    }
  };

  /**
   * Get temp text
   * @param text
   * @returns
   */
  getText = (text: string) => <div className="temp-text">{`${text}`}</div>;

  /**
   * Update tooltip
   * @param e
   * @returns
   */
  updateTooltip = (e: IEvent) => {
    /*
    * when temperature is out of range and
    * set temperature is null or out of range
    */
    let tempValue;
    let loadingBoolen = this.props.loadingMsg;
    let isTempOutOfRange = this.props.value < this.props.min || this.props.value > this.props.max;
    let isSetTempOutOfRange = this.props.toValue < this.props.min || this.props.toValue > this.props.max;
    /**
     * When the temperature or set temperature goes out of boundary
     */
    if(isTempOutOfRange){
      if(isSetTempOutOfRange){
        tempValue = this.props.toValue;
      }else if(isNaN(this.props.toValue)){
        tempValue = this.props.value;
      }
      else {
        tempValue = e.value;
      }
    } else if(isSetTempOutOfRange) {
      tempValue = this.props.toValue;
    } else if (isNaN(this.props.toValue)){
      tempValue = this.props.value;
    } else {
      tempValue = e.value;
    }
    let text = ''
    if(loadingBoolen) {
      text = 'Please wait a moment while we load your content';
      const currentValue = document.getElementById('loading-msg');
 
      if(currentValue) {
        currentValue.classList.remove('invisible');
      }
    } else {
      const currentValue = document.getElementById('loading-msg');
 
      if(currentValue) {
        currentValue.classList.add('invisible');
      }
    }

    if(loadingBoolen) {
      return `<div class=text-container>
      <div class='temp-value mt-45'>${tempValue}&#176;${this.props.unit}
      <p class='error-msg ' id='loading-msg'>${text}</p></div>
      </div>`;
    } else {
      return `<div class=text-container>
      <div class='temp-value'>${tempValue}&#176;${this.props.unit}
      </div>`;
    }

  };

  /**
   * Update gradients
   * @param _value
   */
  updateGradients = (_value: number) => {
    if (_value > this.props.value) {
      this.setState({ reverse: true });
      this.setGradient(true, this.props.value, _value);
    } else {
      this.setState({ reverse: false });
      this.setGradient(false, this.props.value, _value);
    }
  };

  /**
   * Control click event
   * @param isIncrease
   */
  onControlClick = (isIncrease: boolean) => {
    let _updatedValue;
    let toValue =this.props.toValue;
    if(isNaN(toValue) || toValue <= this.props.min){
      _updatedValue = this.props.min;
    }else if (this.props.toValue >= this.props.max){
      _updatedValue = this.props.max;
    }else {
      _updatedValue = toValue;
    }
    if (isIncrease) {
      _updatedValue += this.props.step;
    } else {
      _updatedValue -= this.props.step;
    }
    this.convertAndUpdate(_updatedValue.toString());
    this.updateGradients(_updatedValue);
  };
  /**
   * Convert and Update
   * @param _value
   */
  convertAndUpdate = (_value: string) => {
    const _cVlaue =parseFloat(_value);
    this.props.onChange(_cVlaue);
  };

  /**
   * Request for Failed Message
   * @param failed
   */
  getRequestFailedMsg = (failed: boolean) => {
    if (failed) {
      //div for failed message
      return <div className="temp-error">Request failed, resetting temp</div>;
    } else {
      return null;
    }
  };

  // Component render
  render() {
    // Place holder variable for the slider value
    let sliderValue: string = '';
    if (this.state.reverse) {
      // Slider value if it is reverse
      sliderValue = `${this.props.value},${this.props.toValue}`;
    }
    else {
      // Slider value if it is not reverse
      sliderValue = `${this.props.toValue},${this.props.value}`;
    }

    let thumbPosition = this.props.toValue;
    let currentValue = this.props.value;
    const { min, max, step } = this.props;
    let id: string;
    if (!this.props.tempReqInProgress) {
      //id when temperature request is in progress
      id = 'appearance-disabled';
    } else if (this.state.reverse) {
      //id when temperature value is reversed
      id = 'appearance1';
    } else {
      //id for no change
      id = 'appearance0';
    }
    let upIconColor : string = '#39bbff';
    let downIconColor : string = '#39bbff';
    if(thumbPosition >= max || currentValue >=max ){
      upIconColor = '#BBBBBB';
    }
    if(thumbPosition <= min || isNaN(thumbPosition) || currentValue <=min){
      downIconColor = '#BBBBBB';
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative' }}
      >
        {/* in progress text */}
        {this.getText(this.props.progressText)}
        {/* failed message */}
        {this.getRequestFailedMsg(this.props.requestFailed)}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center' }}
        >
          {/* div for round slider */}
          <div style={{ position: 'absolute' }} id={id}>
            <RoundSlider
              sliderType="default"
              value={thumbPosition}
              startAngle="330"
              endAngle="211"
              radius="140"
              width="15"
              handleSize="+10"
              min={min}
              max={max}
              step={step}
              lineCap="round"
              tooltipFormat={this.updateTooltip.bind(this)}
              handleShape="dot"
              editableTooltip={false}
              change={(e: IEvent) => {
                this.convertAndUpdate(e.value.toString());
                this.updateGradients(e.value);
              }}
            />
          </div>
          {/* div for round slider */}
          <div id="appearance2">
            <RoundSlider
              sliderType="range"
              update={this.onValChange}
              value={sliderValue}
              startAngle="330"
              endAngle="211"
              radius="140"
              width="15"
              min={min}
              max={max}
              step={step}
              lineCap="round"
              handleShape="default"
              showTooltip="false"
              handleSize="24,4"
              rangeColor="url(#grad1)"
              pathColor="#DEE2E8"
              borderColor="transparent"
            />
          </div>
        </div>
        {/* div for action button */}
        <div className="action-buttons-container">
          <div className="parent">
            {/* div for min temperature */}
            <div className="min">{`${min}° (Min)`}</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row'}}
            >
              {/* div for temperature action down button */}
              <div
                id="temp-action-buttons-down"
                className="icon"
                onClick={() =>{ if (thumbPosition > min || currentValue <=min)
                  {this.onControlClick(false);}}}
              >
                {/* div for temperature action down button */}
                <Icon
                  color={downIconColor}
                  root="global"
                  name="caret-down"
                  size="large"
                />
              </div>
              {/* div for temperature action up button */}
              <div
                id="temp-action-buttons-up"
                className="icon"
                onClick={() => {if(thumbPosition < max || isNaN(thumbPosition) || currentValue < max )
                  {this.onControlClick(true);}}}
              >
                {/* icon for temperature action up button */}
                <Icon
                  color={upIconColor}
                  root="global"
                  name="caret-up"
                  size="large"
                />
              </div>
            </div>
            <div className="max">{`${max}° (Max)`}</div>
          </div>
        </div>
      </div>
    );
  }
}
// Default export
export default TemperatureControl;
