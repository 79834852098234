
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:11-02-2021
 */

// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import '@scuf/common/honeywell/theme.css';
import '@scuf/datatable/honeywell/theme.css';
import store from './stores/Redux/Store';
import { Provider } from 'react-redux';
import createRouter from './router';
import './index.scss';
import './i18next';
import App from './App';

// Here we are importing our stores file and spreading it across this Provider. All stores added to this will be accessible via child injects
const wrappedApp = (
  <Provider store={store}>
      <App/>
  </Provider>
);

// Here the router is bootstrapped
const router = createRouter();

router.start(() => {
  ReactDOM.render(wrappedApp, document.getElementById('root') as HTMLElement);
});