
/*
 * Light and Temperater app for Sine
 * Author:Dheeraj
 * Created on:11-02-2021
 *
 */

import React from 'react';
import { Card} from '@scuf/common';
/*
*
* Component for controlling the lighting
*
*/
const controlTitle = 'BRIGHTNESS (ALL LIGHTS)';
/*
*
* Msg to be displayed when temp is null
*
*/
const TEMPNULLMSG= `If the problem still persists,\nplease contact your facility manager`;

interface UnavailableCardProps {
    checkDataAvailability: Function
    Info:string
    header:string
    isTempNull?:boolean
}
function UnavailableCard(props:UnavailableCardProps) {
    /*
    *
    *
    *
    */
    return (
        <>
            <div className='medium-text bold-600'>
                        {props.header}
            </div>
            <Card className="unavailable-header-card" id='card'>
        <Card.Header className='unavailable-header-title' title={props.Info} />
        <Card.Meta className="unavailable-header-link">
          <a onClick={()=>props.checkDataAvailability()}>
            {'Try again'}
          </a>
          {props.isTempNull && <div className='temp-null-msg'>{TEMPNULLMSG}</div>}
        </Card.Meta>
      </Card>
        </>

    );
}

export default UnavailableCard;