
/*
 *
 * Light and Temperature app for Sine
 * Author: Dheeraj
 * Created on:03-01-2021
 */

// Imports
import React from 'react';
import './SineAppLoader.scss';
import doubleRing from './Double-Ring.gif';
import i18next from '../../i18next';
/*
 *SineAppLoader component
 *
 */
// Component
const sineAppLoader = () => (
  // div for animated image layer
  <div
    className='layer'
  >
    <div className='doubleRing-card'>
    <img src={doubleRing} className='doubleRing'/>
          <div className="doubleRing-header">
          {i18next.t('loaderCardHeader')}
          </div>
          <div className="doubleRing-data-one">
            {i18next.t('loaderCardData1')}
          </div>
          </div>
    </div>
);
// default export
export default sineAppLoader;
