
/*
 * en-US
 * Author: Dheeraj V
 * Created On: 09-22-2021
 * Updated On: 09-15-2022 by Divya Tiwari
 *
 */
export const translations = {
    'en-US': {
      translation: {
        //Temperature Light Control Landing Page
        roomControls:'Room Controls',
        seeAnotherRoom:'Need to see another room?',
        loadFloorRoomsTitle:'Loading list of rooms on this floor',
        loadFloorRoomsDescription:'Please wait while we load all the rooms of the floor you’re currently on.',
        loadAnotherFloorRoomsDescription:'Please wait while we load the list of rooms in this floor.',
        selectRoom:'Select the room you\'re in',
        detectingRoom:'Detecting the room you are in',
        pleaseWait2:'Please wait while we locate the room you are currently in.',
        wirelessDefault:'Tap on bar to set desired brightness level',
        loaderCardHeader:'Finding your precise indoor location',
        loaderCardData1:'Please wait a moment while we identify your precise location.',
        verifyingAccess:'Verifying access',
        verifyingAccessData:'Please wait while we verify your access level to environmental controls.',
        loadingRoomControl:'Loading room controls',
        loadingRoomControlData:'Now that we know what room you\'re in, we\'re getting the room controls ready.',
        someThingWentWrongHearder:'Something went wrong',
        someThingWentWrongData:'We’re not sure what happened here. Please try again.',
        retryButton:'Retry',
        unAuthorizedAccessData:'We are unable to authorize your access to Room Controls. Please try again.',
        nearbyRoomTitle:'Rooms near you',
        loadFloorTitle:'Loading list of floors in this building',
        loadFloorDescription:'Please wait while we load the list of floors in this building.',
        radiusText:'Within {{radius}} meters',
        tryAgainButton:'Try again'}}};